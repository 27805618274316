import { useAuth } from "@with-nx/auth";
import { useSubscription } from "@with-nx/hooks-n-helpers";
import {
  AvailableIconName,
  DesignedIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { RiProjector2Line, RiSlideshow3Line } from "react-icons/ri";
import { Box } from "simple-effing-primitive-layout";

const ITEMS: {
  label: string;
  url?: string;
  slug: string;
  target?: string;
  icon?: AvailableIconName | ReactNode;
  separate?: boolean;
  profile?: boolean;
  press?: () => unknown;
}[] = [
  {
    slug: "my-account",
    label: "My Account",
    url: "/profile/account",
    profile: true,
  },
  {
    slug: "my-orders",
    label: "Order History",
    url: "/profile/order-history",
    icon: "list/regular",
  },
  {
    slug: "my-rentals",
    label: "My Rentals",
    url: "/profile/rentals",
    icon: <RiSlideshow3Line color="white" size={18} title="slideshow icon" />,
  },
  {
    slug: "my-hardware",
    label: "My Hardware",
    url: "/profile/my-hardware",
    icon: <RiProjector2Line color="white" size={18} title="projector icon" />,
  },
  {
    slug: "my-representative",
    label: "My Representative",
    url: "/profile/my-representative",
    icon: "user/regular",
  },
  {
    slug: "my-organizations",
    label: "My Organizations",
    url: "/profile/my-organizations",
    icon: "users",
  },
  {
    slug: "help-center",
    label: "Help Center",
    url: "https://help.broadwaymedia.com/",
    target: "_blank",
    icon: "filequestion/regular",
    separate: true,
  },
];

const SidebarMenuDesktopItem = ({
  i,
  item,
  name,
  email,
  close,
}: {
  close?: () => unknown;
  i: number;
  item: {
    label: string;
    url?: string;
    slug: string;
    target?: string;
    icon?: AvailableIconName | ReactNode;
    profile?: boolean;
    separate?: boolean;
    press?: () => unknown;
  };
  name?: string;
  email?: string;
}) => {
  const router = useRouter();
  const [hover, _hover] = useState(false);

  return (
    <div onMouseEnter={() => _hover(true)} onMouseLeave={() => _hover(false)}>
      <Box
        top={i === 0 ? 0 : 8}
        parse={`h:52 w:100% pl:${
          item.icon || item.profile ? 8 : 16
        } d:flex a:center br:5`}
        key={item.slug}
        press={() => {
          if (item.press) {
            item.press();
          } else {
            if (item.target) {
              window?.open(item.url, item.target);
              close?.();
            } else {
              router.push(item.url || "#");
              close?.();
            }
          }
        }}
        color={hover ? "#2D3542" : "transparent"}
      >
        {item.profile && name ? (
          <Box
            css="br:999 d:inline-flex a:center j:center"
            parse="w:36 h:36 c:?accent mr:12"
          >
            <Rule color="var(--font1)" rule="ls">
              {(() => {
                if (name.includes(" ")) {
                  return name
                    .split(" ")
                    .map((item) => item[0])
                    .join("");
                }
                return null;
              })()}
            </Rule>
          </Box>
        ) : item.icon ? (
          <Box
            css="br:999 d:inline-flex a:center j:center"
            parse="w:36 h:36 c:?accent mr:12"
          >
            {typeof item.icon === "string" ? (
              <DesignedIcon
                name={item.icon as AvailableIconName}
                color="var(--font1)"
                size={18}
              />
            ) : (
              item.icon
            )}
          </Box>
        ) : undefined}
        <Box>
          <Rule display="block" color="var(--font1)" rule="ls">
            {item.label}
          </Rule>
          {item.profile && email ? (
            <Rule display="block" color="var(--font3)" rule="ls">
              {email}
            </Rule>
          ) : undefined}
        </Box>
      </Box>
      {item.separate || item.profile ? (
        <Box css="w:100% d:block" parse="mt:8 h:1 c:?accent" />
      ) : undefined}
    </div>
  );
};

export const ProfileSidebar = ({
  email,
  name,
  close,
}: {
  email?: string;
  name?: string;
  close?: () => unknown;
}) => {
  const { logout } = useAuth();
  const subscription = useSubscription();
  const items = [
    ...ITEMS,
    ...(subscription.active
      ? [
          {
            slug: "my-membership",
            label: "My Membership",
            url: "/profile/my-membership",
            icon: (
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.81663 11.9999C6.86625 10.1241 10.1217 6.86929 11.9983 2.82013C13.8708 6.86898 17.1304 10.1227 21.1796 11.9984C17.1309 13.8709 13.8772 17.1305 12.0016 21.1796C10.1285 17.1296 6.86744 13.8752 2.81663 11.9999Z"
                  stroke="white"
                  strokeWidth="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_50"
                    x1="0.539299"
                    y1="0.0693767"
                    x2="26.4864"
                    y2="0.0693765"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#46C2F2" />
                    <stop offset="1" stopColor="#1875FF" />
                  </linearGradient>
                </defs>
              </svg>
            ),
          },
          {
            slug: "my-collections",
            label: "My Collections",
            url: "/profile/my-collections",
            icon: (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 2.25H2.25V7.5H7.5V2.25Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.75 2.25H10.5V7.5H15.75V2.25Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.75 10.5H10.5V15.75H15.75V10.5Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 10.5H2.25V15.75H7.5V10.5Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            press: undefined,
          },
        ]
      : []),
    {
      slug: "log-out",
      label: "Log Out",
      icon: <FiLogOut size={18} color="var(--font1)" title="logout icon" />,
    },
  ];

  return (
    <Box css="br:10" parse="c:?foreground pa:10">
      {items.map((item, i) => (
        <SidebarMenuDesktopItem
          close={close}
          key={item.slug}
          item={{
            ...item,
            press:
              item.slug === "log-out"
                ? () => {
                    logout();
                    setTimeout(() => {
                      if (window) {
                        window.location.pathname = "/";
                      }
                    }, 100);
                    close?.();
                  }
                : item.press,
          }}
          i={i}
          name={name}
          email={email}
        />
      ))}
    </Box>
  );
};

export default ProfileSidebar;
