import { useEffect, useState } from "react";

import useService from "./useService";

export type UseStaff = {
  id: string;
  type: "Staff";
  email: string;
  firstName?: string;
  lastName?: string;
  title?: string | null;
  customerServiceTeam: boolean;
  isLeadership: boolean;
  attachments?: {
    blob: {
      signedUrl: string;
    };
  }[];
};

export const useStaff = () => {
  const [data, _data] = useState<UseStaff[]>([]);

  useEffect(() => {
    const service = useService("microservice", {
      cache: 60_000,
    });

    (async () => {
      const request = await service("GET", "/backstage/staff", {
        "pagination[pageSize]": "500",
        "pagination[page]": "1",
      });

      const staff: UseStaff[] = request?.["result"] || [];

      const customerServiceTeam = staff.filter(
        (s) => s.customerServiceTeam && Number(s.title?.length || 0) > 0
      );
      const leadershipTeam = staff.filter(
        (s) =>
          s.isLeadership &&
          !s.customerServiceTeam &&
          Number(s.title?.length || 0) > 0
      );
      const otherTeam = staff.filter(
        (s) =>
          !s.customerServiceTeam &&
          !s.isLeadership &&
          Number(s.title?.length || 0) > 0
      );

      const customerServiceTeamOrdered = customerServiceTeam.sort((a, b) => {
        if (a.firstName && b.firstName) {
          return a.firstName.localeCompare(b.firstName);
        }
        return 0;
      });

      const leadershipTeamOrdered = leadershipTeam.sort((a, b) => {
        if (a.firstName && b.firstName) {
          return a.firstName.localeCompare(b.firstName);
        }
        return 0;
      });

      const otherTeamOrdered = otherTeam.sort((a, b) => {
        if (a.firstName && b.firstName) {
          return a.firstName.localeCompare(b.firstName);
        }
        return 0;
      });

      const staffOrdered = [
        ...customerServiceTeamOrdered,
        ...leadershipTeamOrdered,
        ...otherTeamOrdered,
      ];

      _data(staffOrdered);
    })();
  }, []);

  return data;
};

export default useStaff;
