import { createContext, useContext, useEffect, useState } from "react";

import { useMediaQuery } from "./useMediaQuery";

export type Mobile = {
  mobile: boolean;
};

export const MobileContext = createContext<Mobile | undefined>(undefined);

export const MobileProvider = ({
  children,
  width = 767,
}: {
  children: JSX.Element | JSX.Element[];
  width?: number;
}) => {
  const [mobile, _mobile] = useState(false);

  const query = useMediaQuery(width);

  /* This approach is to prevent hydration errors from NextJS. */
  useEffect(() => {
    _mobile(query.smaller);
  }, [Boolean(query.smaller)]);

  return (
    <MobileContext.Provider
      value={{
        mobile: mobile,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = (width: number = 767, origin?: string) => {
  const context = useContext(MobileContext);

  if (!context) {
    throw new Error(
      `useMobile must be used within an MobileProvider - ${origin}`
    );
  }

  return Boolean(context.mobile);
};

export const useCustomMobile = (width: number = 767) => {
  const [mobile, _mobile] = useState(false);

  const query = useMediaQuery(width);

  /* This approach is to prevent hydration errors from NextJS. */
  useEffect(() => {
    _mobile(query.smaller);
  }, [Boolean(query.smaller)]);

  return mobile;
};

export default useMobile;
