import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "../useService";

type StageGearRental = {
  id: number;
  tracking_codes: string[];
  shippo_return_label_url: string;
  start_date: string;
  end_date: string;
  created_at: string;
  stagegear_product: {
    id: number;
    name: string;
    description: string;
    specs: {
      name: string;
      detail: string;
    }[];
  };
};

export const useStageGearRentals = () => {
  const [rentals, _rentals] = useState<StageGearRental[]>([]);

  const auth = useAuth();

  useEffect(() => {
    (async () => {
      const makeRequestToBackstage = useService("accounts");

      const request = await makeRequestToBackstage(
        "GET",
        "/api/v2/stagegear_rentals",
        undefined,
        {
          Authorization: `Bearer ${auth?.authState?.session?.token}`,
        }
      );

      if (request?.["data"]) {
        _rentals(request["data"]);
      }
    })();
  }, []);

  return rentals;
};
