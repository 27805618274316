import { Box } from "simple-effing-primitive-layout";

export const ReusablePreSlantedContainer = ({
  children,
  active = true,
  amount = 32,
}: {
  children: unknown;
  active?: boolean;
  amount?: number;
}) => {
  return (
    <Box style={{ paddingBottom: active ? "calc(64px + 96px)" : amount }}>
      {children}
    </Box>
  );
};

export default ReusablePreSlantedContainer;
