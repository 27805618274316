import {
  LoadingShimmer,
  PlaceholderImage,
  Rule,
} from "@with-nx/simple-ui/atoms";
import Moment from "moment";
import Image from "next/image";
import { FiArrowUpRight, FiCalendar } from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

interface GridBlogProps {
  loading?: boolean;
  title?: string;
  image?: string;
  date?: string;
  description?: string;
  url?: string;
  transparent?: boolean;
  properties?: {
    [key: string]: unknown;
  };
  label?: string;
}

export const GridBlog = (props: GridBlogProps) => {
  const {
    image,
    title,
    loading,
    date,
    description,
    url,
    transparent = false,
    properties,
    label = "Continue Reading",
  } = props;

  return loading ? (
    <Box css="d:flex fd:column mb:0 a:flex-start">
      <Box parse="w:100% pt:56.25% br:5 oa:hidden p:relative mb:12">
        <PlaceholderImage
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
      <LoadingShimmer parse="c:?foreground h:16 w:77 mb:12 br:5" />

      <LoadingShimmer parse="c:?foreground h:14 w:100% mb:2 br:5" />
      <LoadingShimmer parse="c:?foreground h:14 w:65% mb:12 br:5" />

      <LoadingShimmer parse="c:?foreground h:14 w:100% mb:2 br:5" />
      <LoadingShimmer parse="c:?foreground h:14 w:100% mb:2 br:5" />
      <LoadingShimmer parse="c:?foreground h:14 w:80% mb:12 br:5" />

      <LoadingShimmer parse="c:?foreground h:32 w:88 br:5" />
    </Box>
  ) : (
    <Box
      parse="d:flex fd:column mb:0 br:4 a:flex-start"
      color={transparent ? "transparent" : "#171B20"}
      {...properties}
      element="a"
      native={{
        href: url || undefined,
        rel: "noreferrer",
      }}
    >
      <Box css="p:relative w:100%">
        <Box
          parse="w:100% h:200 oa:hidden p:relative c:?foreground d:block"
          style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        >
          {image ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <Image
                src={image}
                alt={title}
                loading="lazy"
                objectFit="cover"
                layout="fill"
              />
            </div>
          ) : (
            <PlaceholderImage
              style={{
                width: "100%",
                height: "auto",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}
        </Box>
      </Box>
      <Box parse="pa:16 d:flex fd:column j:space-between h:100%">
        <Box>
          <Rule
            rule="lm"
            display="block"
            bottom={8}
            color="var(--font1)"
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              lineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Rule>
          <Rule
            rule="ps"
            display="block"
            bottom={16}
            color="var(--font3)"
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              lineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {description}
          </Rule>
        </Box>
        <Box css="d:flex a:center j:space-between">
          <Box parse="d:inline-flex a:center">
            <FiArrowUpRight
              size={16}
              color="var(--primary)"
              title="arrow upright icon"
            />
            <Rule rule="ls" display="block" left={4} color="var(--primary)">
              {label}
            </Rule>
          </Box>
          {date ? (
            <Box parse="d:inline-flex a:center ml:6">
              <FiCalendar size={16} color="#909090" title="calendar icon" />
              <Rule rule="ls" display="block" left={4} color="#909090">
                {Moment(date).isValid()
                  ? Moment(date).format("D MMM YYYY")
                  : date}
              </Rule>
            </Box>
          ) : undefined}
        </Box>
      </Box>
    </Box>
  );
};

export default GridBlog;
