import "../styles/globals.css";
import "../styles/fonts.css";
import "../styles/homepage.scss";
import "simple-effing-primitive-layout/dist/styles.min.css";

import { ChakraProvider } from "@chakra-ui/react";
import { GoogleTagManagerNextJs } from "@with-nx/analytics";
import { AuthGate, AuthProviderClass } from "@with-nx/auth";
import { StripeGate, store } from "@with-nx/ecommerce";
import { MobileProvider, useApplication } from "@with-nx/hooks-n-helpers";
import { RegionProvider } from "@with-nx/region";
import { CookiePolicyBanner } from "@with-nx/simple-ui/organisms";
import type { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

export default function App({ Component, pageProps }: AppProps) {
  const application = useApplication();
  const development = process.env.NODE_ENV !== "production";

  return (
    <AuthProviderClass
      configuration={{
        development,
        host: true,
      }}
    >
      <AuthGate>
        <RegionProvider>
          <StripeGate>
            <Provider store={store}>
              <ChakraProvider>
                <MobileProvider>
                  <>
                    <Component {...pageProps} />
                    {application.print ? undefined : <CookiePolicyBanner />}
                  </>
                </MobileProvider>
              </ChakraProvider>
            </Provider>
          </StripeGate>
        </RegionProvider>
      </AuthGate>

      {development ? undefined : <GoogleTagManagerNextJs />}
      <Toaster
        toastOptions={{
          style: {
            border: "unset",
            padding: "16px",
            color: "white",
            background: "var(--foreground)",
          },
        }}
      />
    </AuthProviderClass>
  );
}
