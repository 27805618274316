import { useNoPrint } from "@with-nx/hooks-n-helpers";
import { DesignedTag, Rule } from "@with-nx/simple-ui/atoms";
import { FooterTemplate } from "@with-nx/simple-ui/templates";
import { Meta } from "@with-nx/static";
import { Navbar } from "@with-nx/static-brand-components";
import Head from "next/head";
import { useMemo } from "react";
import { Box } from "simple-effing-primitive-layout";

interface CatalogLayoutProps {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  showCart?: boolean;
  comingSoon?: boolean;
  hideNavBar?: boolean;
  hideFooter?: boolean;
  transparentFixedNavBar?: boolean;
  hideAuthButtons?: boolean;
  hideProfileActions?: boolean;
  showAuthorizedMessage?: boolean;
  footerNoAngle?: boolean;
  authorizedLogout?: () => unknown;
  resolvedUrl?: string;
}

const AdditionalMetaTags = ({
  title,
  description,
  resolvedUrl,
}: CatalogLayoutProps) => {
  const noprint = useNoPrint();

  const resolvedMeta = useMemo(() => {
    try {
      const search = Meta?.find((entry) => entry?.slug === resolvedUrl);
      return search;
    } catch (error) {
      console.error("Error parsing meta.json", error);
      return undefined;
    }
  }, [resolvedUrl]);

  return (
    <Head>
      <title>
        {resolvedMeta?.title?.trim() ||
          title ||
          "Broadway Media - Theatrical Resources"}
      </title>
      <meta
        name="description"
        content={
          resolvedMeta?.description?.trim() ||
          description ||
          "Breaking down barriers to the performing arts through world-class production resources."
        }
      />
      <meta charSet="utf-8" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width, initial-scale=1, viewport-fit=cover"
        name="viewport"
      />
      <meta name="robots" content="follow, index" />
      <meta name="theme-color" content="#e2b677" />
      <meta name="background-color" content="#0e1115" />
      <meta name="color-scheme" content="dark" />
      <link href="/favicon.png" rel="shortcut icon" />
      <link href="/manifest.json" rel="manifest" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap"
        rel="stylesheet"
      />
      {noprint ? (
        <style>
          {`
                html {
                  zoom: 0.5;
                  height: 100%;
                }

                * {
                  overflow: hidden !important;
                  pointer-events: none !important;
                }
              `}
        </style>
      ) : undefined}
    </Head>
  );
};

export const AuthorizedMessage = ({ authorizedLogout }: CatalogLayoutProps) => {
  return (
    <Box css="d:flex a:center j:center" parse="h:48" color="var(--foreground)">
      <DesignedTag
        size="small"
        color="var(--font2)"
        background="var(--accent)"
        properties={{
          style: {
            boxShadow: "box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
          },
          right: 12,
        }}
        label="Log Out"
        press={() => {
          authorizedLogout?.();
        }}
      />
      <Rule parse="!lt" color="var(--font3)">
        You are currently logged in as a visitor.
      </Rule>
    </Box>
  );
};

export function CatalogLayout({
  children,
  title,
  description,
  hideNavBar,
  hideProfileActions,
  transparentFixedNavBar,
  hideFooter,
  footerNoAngle,
  hideAuthButtons,
  showAuthorizedMessage,
  authorizedLogout,
  resolvedUrl,
}: CatalogLayoutProps) {
  return (
    <>
      <div id="__layout__" />
      <AdditionalMetaTags
        title={title}
        description={description}
        resolvedUrl={resolvedUrl}
      />
      {showAuthorizedMessage ? (
        <AuthorizedMessage authorizedLogout={authorizedLogout} />
      ) : undefined}
      {!hideNavBar ? (
        <Navbar
          transparentFixedNavBar={transparentFixedNavBar}
          hideAuthButtons={hideAuthButtons}
          hideProfileActions={hideProfileActions}
        />
      ) : undefined}

      <div className="w-full bg-brand-background">{children}</div>

      {!hideFooter ? (
        <FooterTemplate footerNoAngle={footerNoAngle} />
      ) : undefined}
    </>
  );
}

export default CatalogLayout;
