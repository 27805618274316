import { useService } from "@with-nx/hooks-n-helpers";
import { useEffect, useState } from "react";

export type UseDropShow = {
  id: string;
  name: string;
  slug: string;
  coverDrop?: {
    id: string;
    name: string;
    attachments: {
      name: string;
      blob: {
        signedUrl: string;
      };
    }[];
  };
};

export const useDropShows = ({
  page = 1,
  per = 12,
  region = 1,
}: {
  page?: number;
  per?: number;

  region?: number;
}) => {
  const [loading, _loading] = useState<boolean>(false);
  const [data, _data] = useState<UseDropShow[]>([]);
  const [pages, _pages] = useState<number>(0);

  useEffect(() => {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    let current = true;

    (async () => {
      const request = await makeRequestToMicroservice(
        "GET",
        `/ecommerce/products/search/${region}/digital-drops/show-brands`,
        {
          "pagination[page]": page.toString(),
          "pagination[pageSize]": per.toString(),
          "includes[coverDrop]": "true",
          "includes[coverDrop_attachments]": "true",
        }
      );

      if (request?.["result"] && current) {
        _data(request?.["result"]);
        _pages(Math.ceil(request?.["total"] / request?.["pageSize"]));
        _loading(false);
      }
    })();

    return () => {
      current = false;
    };
  }, [page, region]);

  return {
    loading,
    data,
    pages,
  };
};

export default useDropShows;
