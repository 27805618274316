import { Tooltip } from "@with-nx/editor-ui";
import React, { RefObject, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import {
  AvailableIconName,
  DesignedIcon,
} from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

export interface DesignedInputProps {
  icon?: AvailableIconName;
  loading?: boolean;
  minimal?: boolean;
  label?: string;
  tooltip?: string;
  hint?: string;
  value?: string | number;
  disable?: boolean;
  error?: string | boolean;
  positive?: string;
  size?: string;
  required?: boolean;
  color?: string;
  type?: string;
  placeholder?: string;
  bottom?: number;
  focus?: () => void;
  blur?: () => void;
  change?: (value: string, event?: unknown) => void;
  properties?: {
    [key: string]: unknown;
  };
  container?: {
    [key: string]: unknown;
  };
  native?: {
    [key: string]: unknown;
  };
  reference?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  textarea?: boolean;
  convert?: "none" | "uppercase" | "lowercase" | "capitalize";
  fullWidth?: boolean;
  theme?: DesignedInputTheme;
  optionsOpen?: boolean;
}

type DesignedInputSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    size?: number;
    weight?: string;
    label?: {
      rule?: string;
      bottom?: number;
    };
    hint?: {
      top?: number;
    };
    textarea?: {
      height?: number;
      padding?: {
        horizontal: number;
        vertical: number;
      };
    };
  };
};

const Sizes: DesignedInputSizes = {
  xl: {
    height: 56,
    padding: 30,
    size: 20,
    weight: "500",
    label: {
      rule: "pm",
      bottom: 10,
    },
    hint: {
      top: 10,
    },
    textarea: {
      height: 43,
      padding: {
        horizontal: 30,
        vertical: 15,
      },
    },
  },
  large: {
    height: 48,
    padding: 16,
    size: 18,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
  medium: {
    height: 40,
    padding: 16,
    size: 16,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
  small: {
    height: 32,
    padding: 10,
    size: 14,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
};

type DesignedInputTheme = "light" | "dark";

const Themes = {
  light: {
    label: {
      color: "var(--black-base)",
    },
    container: {
      color: "#FFF",
      border: {
        default: "1.3px solid var(--grey-40)",
        focus: "1.3px solid var(--hoover)",
        filled: "2px solid var(--grey-60)",
        error: "1.3px solid var(--error)",
      },
    },
    input: {
      color: "var(--black-base)",
      placeholder: "var(--grey-20)",
    },
    error: {
      color: "var(--error)",
    },
  },
  dark: {
    label: {
      color: "#AFAFAF",
    },
    container: {
      color: "#272E38",
      border: {
        default: "var(--border)",
        focus: "var(--border)",
        filled: "var(--border)",
        error: "1px solid var(--negative)",
      },
      focusBorder: "var(--border)",
    },
    input: {
      color: "#FFF",
      placeholder: "var(--grey-20)",
    },
    error: {
      color: "var(--negative)",
    },
  },
};

export const DesignedInput = ({
  icon,
  label,
  hint,
  value,
  disable,
  error,
  focus,
  required,
  blur,
  change,
  properties,
  container,
  size = "medium",
  placeholder,
  type,
  native,
  bottom = 10,
  positive,
  reference,
  tooltip,
  color,
  textarea,
  minimal,
  loading,
  convert = "none",
  fullWidth,
  theme = "dark",
  optionsOpen,
}: DesignedInputProps) => {
  const _size = size ? Sizes[size] || Sizes["medium"] : Sizes["medium"];
  const _theme = Themes[theme];
  const [focused, setFocused] = useState(false);

  const filled = !!value && value?.toString()?.trim() !== "";
  const containerBorder = () => {
    let border: "default" | "focus" | "filled" | "error" = "default";

    if (theme === "light") {
      if (focused) return "focus";
      if (error) return "error";
      if (filled) return "filled";
    }

    if (type === "autocomplete" && optionsOpen) {
      if (error) return "error";
      return "focus";
    }

    return border;
  };

  const inputColor = () => {
    if (type === "date") {
      if (!value && !focused) return _theme.input.placeholder;
      if (focused) return _theme.input.color;
      if (error) return _theme.error.color;
    }

    return _theme.input.color;
  };

  const _convert = (s?: string) => {
    if (!s) return s;

    switch (convert) {
      case "uppercase":
        return s?.toUpperCase();
      case "lowercase":
        return s?.toLowerCase();
      case "capitalize":
        return s
          ?.toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

      default:
        return s;
    }
  };

  return minimal ? (
    <Box parse="w:auto" bottom={bottom} {...container}>
      <Box parse="d:flex a:center">
        {label ? (
          <Rule display="block" rule="lt" color="#AFAFAF" bottom={5} right={5}>
            {label}
          </Rule>
        ) : undefined}
      </Box>
      <Box {...properties}>
        {icon ? (
          <DesignedIcon
            name={icon}
            size={(_size.size || 12) + 6}
            color={color || "var(--font4)"}
          />
        ) : undefined}
        <input
          value={value || ""}
          required={required}
          style={{
            background: "transparent",
            outline: "none",
            outlineColor: "none",
            border: "none",
            height: "100%",
            flex: 1,
            fontSize: _size.size,
            fontWeight: _size.weight,
            fontFamily: "Figtree",
            color: "#FFFFFF",
            marginLeft: icon ? 10 : 0,
            resize: "none",
            width: fullWidth ? "100%" : "auto",
          }}
          placeholder={placeholder}
          onChange={(event) => {
            if (change) {
              change(_convert(event.target.value) || "", event);
            }
          }}
          onFocus={() => {
            setFocused(true);
            if (focus) {
              focus();
            }
          }}
          onBlur={() => {
            setFocused(false);
            if (blur) {
              blur();
            }
          }}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          autoComplete="off"
          {...native}
        />
      </Box>
      {hint ? (
        <Rule display="block" rule="lt" color="#909090">
          {hint}
        </Rule>
      ) : undefined}
      {error && typeof error === "string" && error.length > 0 && !focused ? (
        <Rule display="block" rule="lt" color="var(--negative)">
          {error}
        </Rule>
      ) : undefined}
      {positive && positive.length > 0 ? (
        <Rule display="block" rule="lt" color="var(--positive)">
          {positive}
        </Rule>
      ) : undefined}
    </Box>
  ) : textarea ? (
    <Box parse="w:auto" bottom={bottom} {...container}>
      <Box parse="d:flex a:center">
        {label ? (
          <Rule
            display="block"
            rule={_size.label?.rule}
            color={_theme.label.color}
            bottom={_size.label?.bottom}
            right={5}
          >
            {label}
          </Rule>
        ) : undefined}
        {tooltip ? (
          <Tooltip label={tooltip} position="top-right">
            <DesignedIcon size={12} color="var(--font4)" name="info/bold" />
          </Tooltip>
        ) : undefined}
      </Box>
      <Box
        parse={`
          d:flex a:center j:flex-start br:5
          h:${(_size.textarea?.height || _size.height || 40) * 4.6}
          pt:${_size.textarea?.padding?.vertical || _size.padding}
          pb:${_size.textarea?.padding?.vertical || _size.padding}
          pl:${_size.textarea?.padding?.horizontal || _size.padding} 
          pr:${_size.textarea?.padding?.horizontal || _size.padding}
        `}
        color={_theme.container.color}
        border={_theme.container.border[containerBorder()]}
        padding={_size.textarea?.padding || ""}
        {...properties}
      >
        {icon ? (
          <DesignedIcon
            name={icon}
            size={(_size.size || 12) + 6}
            color={color || "var(--font4)"}
          />
        ) : undefined}
        <textarea
          ref={reference as RefObject<HTMLTextAreaElement>}
          value={value || ""}
          required={required}
          style={{
            background: "transparent",
            outline: "none",
            outlineColor: "none",
            border: "none",
            height: "100%",
            flex: 1,
            fontSize: _size.size,
            fontWeight: _size.weight,
            fontFamily: "Figtree",
            color: _theme.input.color,
            marginLeft: icon ? 10 : 0,
            resize: "none",
          }}
          placeholder={placeholder}
          onChange={(event) => {
            if (change) {
              change(_convert(event.target.value) || "", event);
            }
          }}
          onFocus={() => {
            setFocused(true);
            if (focus) {
              focus();
            }
          }}
          onBlur={() => {
            setFocused(false);
            if (blur) {
              blur();
            }
          }}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          autoComplete="off"
          {...native}
        />
      </Box>
      {hint ? (
        <Rule display="block" rule="lt" color="#909090">
          {hint}
        </Rule>
      ) : undefined}
      {error && typeof error === "string" && error.length > 0 && !focused ? (
        <Rule display="block" rule="lt" color="var(--negative)">
          {error}
        </Rule>
      ) : undefined}
      {positive && positive.length > 0 ? (
        <Rule display="block" rule="lt" color="var(--positive)">
          {positive}
        </Rule>
      ) : undefined}
    </Box>
  ) : (
    <Box parse="w:auto" bottom={bottom} {...container}>
      <Box parse="d:flex a:cente">
        {label ? (
          <Rule
            display="block"
            rule={_size.label?.rule}
            color={_theme.label.color}
            bottom={_size.label?.bottom}
            right={5}
          >
            {label}{" "}
            {required ? (
              <span
                style={{
                  marginLeft: 4,
                  fontWeight: "bold",
                  color: "var(--primary)",
                }}
              >
                *
              </span>
            ) : undefined}
          </Rule>
        ) : undefined}
        {tooltip ? (
          <Tooltip label={tooltip} position="top-right">
            <DesignedIcon size={12} color="var(--font4)" name="info/bold" />
          </Tooltip>
        ) : undefined}
      </Box>
      <Box
        parse={`d:flex a:center j:flex-start ${
          type !== "autocomplete" ? "br:5" : ""
        }
          h:${textarea ? (_size.height || 40) * 4.6 : _size.height}
          pr:${type === "date" ? 15 : _size.padding} 
          pl:${_size.padding} 
          pt:${textarea ? _size.padding : 0}
          pb:${textarea ? _size.padding : 0}
        `}
        color={_theme.container.color}
        border={_theme.container.border[containerBorder()]}
        {...properties}
      >
        {loading ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            preserveAspectRatio="xMidYMid"
            className="-simple-ui-designed-button-transition"
          >
            <circle
              cx="8"
              cy="8"
              fill="none"
              stroke={color || "var(--font4)"}
              strokeWidth="2"
              r="6"
              strokeDasharray="28.274333882308138 11.42477796076938"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 8 8;360 8 8"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        ) : icon ? (
          <DesignedIcon
            name={icon}
            size={(_size.size || 12) + 6}
            color={color || "var(--font4)"}
          />
        ) : undefined}
        <input
          ref={reference as RefObject<HTMLInputElement>}
          type={type}
          value={value || ""}
          disabled={disable}
          required={required}
          className={`input-${theme}`}
          style={{
            background: "transparent",
            outline: "none",
            outlineColor: "none",
            border: "none",
            height: _size.height,
            flex: 1,
            fontSize: _size.size,
            fontWeight: _size.weight,
            fontFamily: "Figtree",
            color: inputColor(),
            marginLeft: icon || loading ? 10 : 0,
            ...(icon && type === "date"
              ? {
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  appearance: "none",
                }
              : {}),
          }}
          placeholder={placeholder}
          onChange={(event) => {
            if (change) {
              change(_convert(event.target.value) || "", event);
            }
          }}
          onFocus={() => {
            setFocused(true);
            if (focus) {
              focus();
            }
          }}
          onBlur={() => {
            setFocused(false);
            if (blur) {
              blur();
            }
          }}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          autoComplete="off"
          {...native}
        />
      </Box>

      {size === "xl" && !hint && !error && !positive && (
        <Box parse="h:16 w:100%" top={_size.hint?.top || 0} />
      )}

      {hint ? (
        <Rule
          display="block"
          rule="lt"
          color="#909090"
          top={_size.hint?.top || 0}
          weight={500}
        >
          {hint}
        </Rule>
      ) : undefined}
      {error &&
      typeof error === "string" &&
      error.length > 0 &&
      (type === "date" ? true : !focused) ? (
        <Rule
          display="block"
          rule="lt"
          top={_size.hint?.top || 0}
          color={_theme.error.color}
        >
          {error}
        </Rule>
      ) : undefined}
      {positive && positive.length > 0 ? (
        <Rule display="block" rule="lt" color="var(--positive)">
          {positive}
        </Rule>
      ) : undefined}
    </Box>
  );
};

export default DesignedInput;
