import { useEffect, useState } from "react";
import { ContactDetails, GetStartedDetails } from "./contact-form";
import { useRegion, useStatesCodes } from "@with-nx/region";
import { Database, useBusiness, useMobile } from "@with-nx/hooks-n-helpers";
import { Col, Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

interface StoredValues {
  step: number;
  values: GetStartedDetails;
}

export interface SteppedGetStartedFormProps {
  initial?: GetStartedDetails;
  value?: GetStartedDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  action?: "get-started" | string;
  referrer?: string;
  submit?: () => Promise<void>;
  loading?: boolean;
  align?: "center" | "left";
}

import {
  Autocomplete,
  DesignedButton,
  DesignedInput,
  DesignedRadio,
  DesignedSelect,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Stepper } from "../stepper";
import { Modal } from "@with-nx/simple-ui/molecules";
import ReusableCheckboxSidebar from "../reusable-checkbox-sidebar/reusable-checkbox-sidebar";
import { ShowDataStatic } from "@with-nx/static";
import {
  countryRequiresState,
  debounce,
  getComplementaryValidationValue,
  getDefaultMessageProducts,
  validators,
} from "./helpers";

export const SteppedGetStartedForm = ({
  initial,
  change,
  submit,
  loading,
  action,
  referrer,
  align,
}: SteppedGetStartedFormProps) => {
  const [values, _values] = useState<GetStartedDetails>({
    ...initial,
  });
  const [errors, _errors] = useState<{
    [key: string]: boolean | string;
  }>({});

  const [valid, _valid] = useState(true);
  const [modal, _modal] = useState("");
  const [step, _step] = useState(0);
  const [missingFields, _missingFields] = useState<string[]>([]);

  const region = useRegion();
  const mobile = useMobile();
  const states = useStatesCodes(values.region === "🇨🇦 Canada" ? "CA" : "US");
  const business = useBusiness();

  const helperText = (
    <>
      Even if you haven't completed filling out the form yet, your
      {!mobile && <br />}
      information will be saved for you to return to later.
    </>
  );

  const validateFields = (fieldsToValidate?: string[]) => {
    let newErrors = {
      ...errors,
    };

    const keys = fieldsToValidate?.length
      ? Object.keys(validators).filter((field) =>
          fieldsToValidate?.includes(field)
        )
      : Object.keys(validators);

    for (const field of keys) {
      const value = values[field as keyof GetStartedDetails];

      const validation = validators[field]?.(
        value,
        getComplementaryValidationValue(field, values)
      );

      const hasError =
        (typeof validation === "string" && validation?.length > 0) ||
        validation === false;

      newErrors = {
        ...newErrors,
        [field]: !hasError
          ? ""
          : typeof validation === "string"
          ? validation
          : true,
      };
    }

    return newErrors;
  };

  const handleSubmit = () => {
    _modal("");

    const errors = validateFields();
    const fields = Object.keys(errors).filter((key) => !!errors[key]);
    const valid = Object.values(errors)?.some((error) => !!error) === false;

    _valid(valid);
    _errors(errors);
    _missingFields(fields);

    const fieldsExceptCustomerType = fields?.filter(
      (field) => field !== "customer_type"
    );

    if (fieldsExceptCustomerType?.length > 2) {
      _missingFields(fieldsExceptCustomerType);
      _modal("more");
      return;
    }

    if (fieldsExceptCustomerType?.length >= 1) {
      _missingFields(fieldsExceptCustomerType);
      _modal("fields");
      return;
    }

    if (fields?.length === 1 && fields?.includes("customer_type")) {
      _modal("customer_type");
      return;
    }

    submit?.().then(() => {
      _step(0);
      _values({
        ...initial,
      });
      Database.set("get-started-form", null);
    });
  };

  const clearFieldError = (field: string) => {
    _errors((previousErrors) => ({
      ...previousErrors,
      [field]: "",
    }));
  };

  const validate = (field: string, value?: string) => {
    if (validators[field]) {
      const newValue = value || values[field as keyof GetStartedDetails];
      const validation = validators[field](
        newValue,
        getComplementaryValidationValue(field, values)
      );

      if (validation === false || validation?.length > 0) {
        return typeof validation === "string" ? validation : true;
      }
    }

    return "";
  };

  const handleChange = (field: string, value: string | string[]) => {
    const newValues = { ...values, [field]: value };
    _values(newValues);

    if (["opening_night", "closing_night", "customer_type"].includes(field)) {
      const validation = validate(field, value as string);

      _errors((prevErrors) => ({
        ...prevErrors,
        [field]: validation,
      }));
    }

    Database.set("get-started-form", {
      step,
      values: newValues,
    });
  };

  const debouncedHandleBlur = debounce((field: string) => {
    const validation = validate(field);
    _errors((prevErrors) => ({
      ...prevErrors,
      [field]: validation,
    }));
  }, 100);

  const handleFocus = (field: string) => clearFieldError(field);

  const renderField = (field: string, span?: number) => {
    const size = mobile ? "medium" : "xl";

    switch (field) {
      case "customer_type":
        return (
          <>
            <Rule
              display="block"
              rule="ht"
              color="#D2D2D2"
              weight="700"
              bottom={24}
            >
              Have we worked together before?
            </Rule>
            <Box
              parse={`d:flex a:center fw:wrap ${
                mobile ? "fd:column a:flex-start" : ""
              }`}
            >
              <DesignedRadio
                size="large"
                label="Returning Customer"
                active={values.customer_type === "Returning Customer"}
                press={() =>
                  handleChange("customer_type", "Returning Customer")
                }
                properties={{ right: 24, bottom: mobile ? 20 : 0 }}
              />
              <DesignedRadio
                size="large"
                label="New Customer"
                active={values.customer_type === "New Customer"}
                press={() => handleChange("customer_type", "New Customer")}
                properties={{ right: 24 }}
              />
            </Box>
          </>
        );
      case "name":
        return (
          <Col xs={24}>
            <DesignedInput
              label="What is your name?"
              hint={!!errors["name"] ? "" : "Please enter your full name"}
              value={values.name}
              change={(name) => handleChange("name", name)}
              focus={() => handleFocus("name")}
              blur={() => debouncedHandleBlur("name")}
              error={errors["name"]}
              placeholder="Your name"
              convert="capitalize"
              size={size}
              theme="light"
              native={{
                cypress: "name-field",
              }}
            />
          </Col>
        );
      case "email":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedInput
              label="What is your e-mail?"
              value={values.email}
              change={(email) => handleChange("email", email)}
              blur={() => debouncedHandleBlur("email")}
              focus={() => handleFocus("email")}
              error={errors["email"]}
              placeholder="Your e-mail"
              convert="lowercase"
              size={size}
              theme="light"
              native={{
                cypress: "email-field",
              }}
            />
          </Col>
        );
      case "phone_number":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedInput
              label="What is your phone number?"
              value={values.phone_number}
              change={(phone_number) =>
                handleChange("phone_number", phone_number)
              }
              blur={() => debouncedHandleBlur("phone_number")}
              focus={() => handleFocus("phone_number")}
              error={errors["phone_number"]}
              placeholder="Your phone number"
              size={size}
              theme="light"
              native={{
                cypress: "phone_number-field",
              }}
            />
          </Col>
        );
      case "organization":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedInput
              label="What is your organization name?"
              value={values.organization}
              change={(organization) =>
                handleChange("organization", organization)
              }
              blur={() => debouncedHandleBlur("organization")}
              focus={() => handleFocus("organization")}
              error={errors["organization"]}
              convert="capitalize"
              placeholder="Your organization name"
              size={size}
              theme="light"
              native={{
                cypress: "organization-field",
              }}
            />
          </Col>
        );
      case "organization_type":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedSelect
              icon="down"
              label="Organization Type"
              value={values.organization_type}
              change={(organization_type) =>
                handleChange("organization_type", String(organization_type))
              }
              blur={() => debouncedHandleBlur("organization_type")}
              focus={() => handleFocus("organization_type")}
              error={errors["organization_type"]}
              empty
              options={business.types.map((i) => [
                i.businessType.toString(),
                i.name,
              ])}
              size={size}
              theme="light"
              native={{
                cypress: "organization_type-field",
              }}
            />
          </Col>
        );
      case "region":
      case "state":
        return (
          <>
            {field === "region" && (
              <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
                <DesignedSelect
                  icon="down"
                  label="Where are you located?"
                  value={values.region}
                  change={(region) => handleChange("region", region)}
                  blur={() => debouncedHandleBlur("region")}
                  focus={() => handleFocus("region")}
                  error={errors["region"]}
                  options={region.regions?.map((i) => [i, i]) || []}
                  size={size}
                  theme="light"
                  empty
                  hint={errors["region"] ? "" : "Required"}
                  native={{
                    cypress: "region-field",
                  }}
                />
              </Col>
            )}

            <>
              {countryRequiresState(values?.region || "none") ? (
                <Col xs={24} sm={span || 12} md={span || 24} xl={span || 12}>
                  <DesignedSelect
                    icon="down"
                    label={`Which ${
                      values.region === "🇺🇸 United States"
                        ? "state"
                        : "province"
                    } are you located?`}
                    value={values.state}
                    change={(state) => handleChange("state", state)}
                    blur={() => debouncedHandleBlur("state")}
                    focus={() => handleFocus("state")}
                    error={errors["state"]}
                    options={states?.map(([i, ii]) => [i, ii]) || []}
                    empty
                    size={size}
                    theme="light"
                    native={{
                      cypress: "state-field",
                    }}
                  />
                </Col>
              ) : undefined}
            </>
          </>
        );
      case "show":
        return (
          <Col xs={span || 24}>
            <Autocomplete
              label="Show"
              hint={errors["show"] ? "" : "Required"}
              value={values?.show}
              blur={() => debouncedHandleBlur("show")}
              focus={() => handleFocus("show")}
              error={errors["show"]}
              placeholder="Show Name"
              convert="capitalize"
              size={size}
              optionsOpen={!!open}
              type="autocomplete"
              theme="light"
              native={{
                cypress: "show-field",
              }}
              mobile={mobile}
              onInputChange={(show) => {
                handleChange("show", show || "");
              }}
              onSearch={(value?: string) => {
                const shows = ShowDataStatic.search(value);
                return shows;
              }}
            />
          </Col>
        );
      case "opening_night":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedInput
              label="Opening Date"
              type="date"
              value={values.opening_night}
              change={(opening_night) => {
                clearFieldError("opening_night");
                handleChange("opening_night", opening_night);
              }}
              error={errors["opening_night"]}
              focus={() => handleFocus("opening_night")}
              blur={() => debouncedHandleBlur("opening_night")}
              convert="lowercase"
              size={size}
              theme="light"
              hint={errors["opening_night"] ? "" : "Required"}
              native={{
                cypress: "opening_date-field",
              }}
            />
          </Col>
        );
      case "closing_night":
        return (
          <Col xs={24} sm={span || 24} md={span || 24} xl={span || 12}>
            <DesignedInput
              label="Closing Date"
              type="date"
              value={values.closing_night}
              change={(closing_night) => {
                clearFieldError("closing_night");
                handleChange("closing_night", closing_night);
              }}
              blur={() => debouncedHandleBlur("closing_night")}
              error={errors["closing_night"]}
              focus={() => handleFocus("closing_night")}
              size={size}
              theme="light"
              native={{
                cypress: "closing_night-field",
              }}
            />
          </Col>
        );
      case "message":
        return (
          <Col xs={span || 24}>
            <DesignedInput
              label="What is your message?"
              placeholder="I just came from the Stage Gear Landing page..."
              value={values.message}
              change={(message) => handleChange("message", message)}
              textarea
              size={size}
              theme="light"
              native={{
                cypress: "message-field",
              }}
            />
          </Col>
        );
      case "products":
        return (
          <Col xs={span || 24}>
            <Rule
              display="block"
              rule="pm"
              color="var(--black-base)"
              bottom={12}
            >
              What products are you interested in?
            </Rule>
            <ReusableCheckboxSidebar
              minimal
              theme="light"
              size="xl"
              groups={{
                items: {
                  expanded: true,
                  label: "",
                  items: [
                    {
                      label: "Digital Scenery",
                      value: "Digital Scenery",
                      active: values.products?.includes("Digital Scenery"),
                    },
                    {
                      label: "Projector Hardware",
                      value: "Projector Hardware",
                      active: values.products?.includes("Projector Hardware"),
                    },
                    {
                      label: "Wireless Microphone Systems",
                      value: "Wireless Microphone Systems",
                      active: values.products?.includes(
                        "Wireless Microphone Systems"
                      ),
                    },
                    {
                      label: "Choreography Guides",
                      value: "Choreography Guides",
                      active: values.products?.includes("Choreography Guides"),
                    },
                    {
                      label: "Other Resources",
                      value: "Other Resources",
                      active: values.products?.includes("Other Resources"),
                    },
                  ],
                },
              }}
              change={(groups) => {
                const is = (groups["items"].items.map((item) => item.value) ||
                  []) as string[];

                handleChange("products", is);
              }}
            />
          </Col>
        );

      default:
        return null;
    }
  };

  const submitModalContent = () => {
    if (modal === "more") {
      return (
        <>
          <Rule
            parse={`!lm ${mobile ? "ta:center pl:30 pr:30" : ""}`}
            weight="500"
          >
            Would you be able to fill{" "}
            <Rule parse="!lm" weight="700">
              out all the missing information
            </Rule>{" "}
            below please?
          </Rule>
          <Box top={30}>
            <DesignedButton
              icon="left"
              label="Back to the Form"
              size="large"
              press={() => {
                const isFirstStepMissingField = firstFields.some((field) =>
                  missingFields?.includes(field)
                );
                _step(isFirstStepMissingField ? 0 : 1);
                _modal("");
              }}
              properties={{
                native: {
                  cypress: "back-to-form-button",
                },
              }}
            />
          </Box>
        </>
      );
    }

    const missingValid = !missingFields.some((field) => !!errors[field]);
    const submitButton = (
      <Box top={30}>
        <DesignedButton
          label="Submit Request"
          size="large"
          press={handleSubmit}
          disable={
            missingValid ? false : ["Make sure all fields are filled out."]
          }
          loading={loading}
          properties={{
            native: {
              cypress: "modal-submit-button",
            },
          }}
        />
      </Box>
    );

    if (modal === "fields") {
      return (
        <>
          <Rule
            parse={`!lm ${mobile ? "ta:center pl:30 pr:30" : ""}`}
            weight="500"
          >
            Would you be able to fill{" "}
            <Rule parse="!lm" weight="700">
              following information
            </Rule>{" "}
            down below please?
          </Rule>

          <Box
            parse={`d:flex j:center fd:column c:#FFF pa:30 mt:30 ${
              mobile
                ? "w:100% br:none pl:20 pr:20 pb:30"
                : "pl:60 pr:60 pb:50 mw:640 br:10 mh:276"
            }`}
          >
            <Row>{missingFields.map((field) => renderField(field, 24))}</Row>
          </Box>
          {submitButton}
        </>
      );
    }

    if (modal === "customer_type") {
      return (
        <>
          {renderField("customer_type")}
          {submitButton}
        </>
      );
    }

    return null;
  };

  const firstFields = [
    "name",
    "email",
    "phone_number",
    "organization",
    "organization_type",
    "region",
    "state",
  ];
  const secondFields = ["show", "opening_night", "closing_night"];

  const steps = [
    {
      title: "Contact Information",
      render: () => {
        return (
          <Row gutter={[30, 10]}>
            {firstFields
              .filter((field) => field !== "state")
              .map((field) => renderField(field))}
          </Row>
        );
      },
    },
    {
      title: "Show Information",
      render: () => {
        return (
          <Row gutter={[30, 20]}>
            {secondFields.map((field) => renderField(field))}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Additional
          <br />
          Information
        </>
      ),
      render: () => {
        const fields = ["message", "products"];

        return (
          <Row gutter={[30, 20]}>
            {fields.map((field) => renderField(field))}
          </Row>
        );
      },
      action: {
        label: "Submit Request",
        onClick: handleSubmit,
        loading: loading,
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const stored = await Database.get<StoredValues>("get-started-form");
      if (stored) {
        _step(stored.step);
        _values(stored.values);
      }
    })();
  }, []);

  useEffect(() => {
    const defaultValues = getDefaultMessageProducts(action, referrer);

    if (defaultValues) {
      _values({
        ...values,
        message: defaultValues.message,
        products: [
          ...(values.products || []),
          ...(defaultValues.products || []),
        ],
      });
    }
  }, [action, referrer]);

  useEffect(() => {
    change?.(values, valid);
  }, [values, valid]);

  return (
    <>
      <Box
        parse={`d:flex fd:column a:${
          mobile || align === "left" ? "flex-start" : "center"
        }`}
        bottom={42}
      >
        {renderField("customer_type")}
      </Box>

      <Stepper steps={steps} current={step} _current={_step} />
      <Modal
        centered
        open={!!modal}
        onClose={() => _modal("")}
        mobile={mobile}
        style={
          mobile
            ? {
                marginLeft: 30,
                marginRight: 30,
              }
            : undefined
        }
      >
        <Box
          parse={`d:flex fd:column a:center j:center pt:50 ${
            mobile ? "mt:8 mb:30 ml:-24 mr:-24" : "mb:50"
          }`}
        >
          <Rule
            parse={`!hl ${mobile ? "ta:center" : ""}`}
            weight="700"
            bottom={10}
          >
            Before You Submit
          </Rule>
          {submitModalContent()}
        </Box>
      </Modal>
    </>
  );
};
