import { UseCMSTestimonial, useMobile } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { FC, useEffect, useState } from "react";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { Box } from "simple-effing-primitive-layout";

interface TestimonialQuotesProps {
  testimonials: UseCMSTestimonial[];
}

export const TestimonialQuotes: FC<TestimonialQuotesProps> = ({
  testimonials,
}) => {
  const mobile = useMobile();
  const [current, _current] = useState(0);
  const [visible, _visible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      _visible(false);

      setTimeout(() => {
        _current((prev) => (prev + 1) % testimonials.length);
        _visible(true);
      }, 800);
    }, 6000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const getDescription = (description?: string) =>
    description
      ? description?.length > 256
        ? description.slice(0, 256) + "..."
        : description
      : null;

  const iconSize = mobile ? 48 : 68;
  const minHeight = mobile ? 272 : 365;

  return (
    <Box parse="w:100% d:flex j:center">
      <Box
        parse={`d:flex fd:column a:center mh:${minHeight}
      ${mobile ? "ml:-32 pr:16" : ""}`}
        style={{
          maxWidth: !mobile ? "738px" : "",
        }}
      >
        <Box
          parse={`p:relative br:10 pa:${
            mobile ? "32" : "20"
          } c:transparent d:flex a:center`}
          style={{
            opacity: visible ? 1 : 0,
            transition: visible
              ? "opacity 2s ease-in-out"
              : "opacity 0.8s ease-in-out",
          }}
        >
          <Box>
            <Box parse="d:inline-block p:absolute t:8 mr:12">
              <RiDoubleQuotesL
                color="var(--font4)"
                size={iconSize}
                title="Quote marks"
              />
            </Box>

            <Box>
              <Rule
                parse={`!${
                  mobile ? "ht" : "hl"
                } mt:16 ml:${iconSize} d:inline-block`}
                color="var(--font1)"
                weight="400"
                tag="p"
              >
                {getDescription(testimonials[current]?.description)}
              </Rule>
              <Rule
                parse={`!ls d:block ml:${iconSize} mt:12`}
                color="var(--font4)"
              >
                {testimonials[current]?.title}
              </Rule>
            </Box>
          </Box>

          <Box
            style={{
              height: "100%",
            }}
          >
            <RiDoubleQuotesR
              style={{
                position: "absolute",
                bottom: "20",
              }}
              color="var(--font4)"
              size={iconSize}
              title="Quote marks"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
