import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "../useService";

type StageGearPurchase = {
  id: number;
  tracking_codes: string[];
  purchased_at: string;
  created_at: string;
  stagegear_product: {
    id: number;
    name: string;
    description: string;
    specs: {
      name: string;
      detail: string;
    }[];
  };
};

export const useStageGearPurchases = () => {
  const [purchases, _purchases] = useState<StageGearPurchase[]>([]);

  const auth = useAuth();

  useEffect(() => {
    (async () => {
      const makeRequestToBackstage = useService("accounts");

      const request = await makeRequestToBackstage(
        "GET",
        "/api/v2/stagegear_purchases",
        undefined,
        {
          Authorization: `Bearer ${auth?.authState?.session?.token}`,
        }
      );

      if (request?.["data"]) {
        _purchases(request["data"]);
      }
    })();
  }, []);

  return purchases;
};
