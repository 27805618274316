import React, { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

type DesignedCheckSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    rule?: string;
    icon?: number;
    weight?: string;
  };
};

const Sizes: DesignedCheckSizes = {
  xl: {
    height: 24,
    padding: 10,
    rule: "lm",
    icon: 24,
    weight: "500",
  },
  large: {
    height: 24,
    padding: 10,
    rule: "ls",
    icon: 20,
    weight: "600",
  },
  medium: {
    height: 20,
    padding: 10,
    rule: "lt",
    icon: 16,
    weight: "600",
  },
  small: {
    height: 16,
    padding: 7,
    rule: "lt",
    icon: 12,
    weight: "600",
  },
};

export interface DesignedCheckProps {
  label?: string | null | undefined | unknown;
  press?: (set?: boolean) => void | null | undefined;
  onChange?: (checked: boolean) => void;
  properties?: {
    [key: string]: unknown;
  };
  active?: boolean;
  disable?: boolean;
  partial?: boolean;
  size?: string;
  theme?: DesignedCheckTheme;
}

export type DesignedCheckTheme = "light" | "dark";

const Themes = {
  light: {
    label: {
      color: "var(--grey-80)",
    },
    checked: {
      background: "var(--gold-120)",
    },
    container: {
      color: "var(--grey-20)",
    },
  },
  dark: {
    label: {
      color: "#FFF",
    },
    checked: {
      background: "var(--primary)",
    },
    container: {
      color: "var(--accent)",
    },
  },
};

export const DesignedCheck = ({
  label = "",
  press,
  properties,
  active = false,
  disable = false,
  partial = false,
  size,
  onChange,
  theme = "dark",
}: DesignedCheckProps) => {
  const _size = size ? Sizes[size] || Sizes["medium"] : Sizes["medium"];
  const _theme = Themes[theme];
  const color = disable ? Colors.font4 : _theme.label.color;
  const checkboxColor = disable ? Colors.font4 : Colors.font1;

  const [value, _value] = useState<boolean>(active);
  useEffect(() => {
    _value(active);
  }, [active]);

  return (
    <Box
      parse="d:inline-flex a:center"
      press={() => {
        if (disable) {
          return;
        }

        const set = !value;
        _value(set);
        if (press) {
          press(set);
        }
        if (onChange) {
          onChange(!set);
        }
      }}
      {...properties}
    >
      <Box
        parse={`h:${_size.height} w:${_size.height} br:5 d:inline-flex a:center j:center`}
        style={{ miwWidth: _size.height }}
        border={disable ? "1px solid rgba(255,255,255,0.05)" : undefined}
        color={
          disable
            ? Colors.font4
            : value || partial
            ? _theme.checked.background
            : _theme.container.color
        }
      >
        {value || partial ? (
          <DesignedIcon
            color={checkboxColor}
            size={_size.icon || 16}
            name={partial ? "minus/filled" : "check/filled"}
          />
        ) : undefined}
      </Box>
      {label ? (
        typeof label === "string" ? (
          <Rule
            style={{ whiteSpace: "nowrap" }}
            left={_size.padding}
            rule={_size.rule}
            color={color}
            weight={_size.weight}
          >
            {label}
          </Rule>
        ) : (
          label
        )
      ) : undefined}
    </Box>
  );
};

export default DesignedCheck;
