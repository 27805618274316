type RegionMetaItem = {
  symbol: string;
  currency: string;
  emoji: string;
  name: string;
  group: string;
  email: string;
  calendly: string;
  instagram: string;
  facebook: string;
  phone?: string;
  addresses?: {
    label: string;
    items: string[];
  }[];
};

type RegionMetaGroup = {
  [key: string]: RegionMetaItem;
};

type ReverseRegionMetaItem = {
  region: string;
};

type ReverseRegionMetaGroup = {
  [key: string]: ReverseRegionMetaItem;
};

export const RegionMeta: RegionMetaGroup = {
  "🇺🇸 United States": {
    symbol: "$",
    currency: "USD",
    emoji: "🇺🇸",
    name: "United States",
    group: "🇺🇸 United States / 🇨🇦 Canada",
    email: "hello@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymedia",
    instagram: "https://www.instagram.com/broadwaymedia/",
    phone: "1-800-277-0343",
    addresses: [
      {
        label: "Billing and Payment Processing",
        items: ["105 W 86th Street, #218", "New York, NY 10024"],
      },
      {
        label: "Shipping and Returns",
        items: ["402 W. Bedford Avenue #101", "Fresno, CA 93711"],
      },
    ],
  },
  "🇨🇦 Canada": {
    symbol: "$",
    currency: "CAD",
    emoji: "🇨🇦",
    name: "Canada",
    group: "🇺🇸 United States / 🇨🇦 Canada",
    email: "hello@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymedia",
    instagram: "https://www.instagram.com/broadwaymedia/",
    phone: "1-800-277-0343",
    addresses: [
      {
        label: "Billing and Payment Processing",
        items: ["105 W 86th Street, #218", "New York, NY 10024"],
      },
      {
        label: "Shipping and Returns",
        items: ["402 W. Bedford Avenue #101", "Fresno, CA 93711"],
      },
    ],
  },
  "🇦🇺 Australia": {
    symbol: "$",
    currency: "AUD",
    emoji: "🇦🇺",
    name: "Australia",
    group: "🇦🇺 Australia / 🇳🇿 New Zealand",
    email: "aus@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymediaau/",
    instagram: "https://www.instagram.com/broadwaymediaau/",
    phone: "+61 2 9098 4319",
    addresses: [
      {
        label: "Australia / New Zealand",
        items: ["Office 4, 20 Welch Street", "Southport, QLD 4215"],
      },
    ],
  },
  "🇳🇿 New Zealand": {
    symbol: "$",
    currency: "NZD",
    emoji: "🇳🇿",
    name: "New Zealand",
    group: "🇦🇺 Australia / 🇳🇿 New Zealand",
    email: "aus@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymediaau/",
    instagram: "https://www.instagram.com/broadwaymediaau/",
    phone: "+61 2 9098 4319",
    addresses: [
      {
        label: "Australia / New Zealand",
        items: ["Office 4, 20 Welch Street", "Southport, QLD 4215"],
      },
    ],
  },
  "🇬🇧 United Kingdom": {
    symbol: "£",
    currency: "GBP",
    emoji: "🇬🇧",
    name: "United Kingdom",
    group: "🇬🇧 United Kingdom / 🇪🇺 Europe",
    email: "europe@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymediaeu/",
    instagram: "https://www.instagram.com/broadwaymediaeu/",
    phone: "+44 20 8089 7801",
    addresses: [
      {
        label: "United Kingdom / Europe",
        items: ["124-128 City Road", "London EC1V 2NX"],
      },
    ],
  },
  "🇪🇺 Europe": {
    symbol: "€",
    currency: "EUR",
    emoji: "🇪🇺",
    name: "Europe",
    group: "🇬🇧 United Kingdom / 🇪🇺 Europe",
    email: "europe@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymediaeu/",
    instagram: "https://www.instagram.com/broadwaymediaeu/",
    phone: "+44 20 8089 7801",
    addresses: [
      {
        label: "United Kingdom / Europe",
        items: ["124-128 City Road", "London EC1V 2NX"],
      },
    ],
  },
  "🌍 Global": {
    symbol: "$",
    currency: "USD",
    emoji: "🌍",
    name: "Global",
    group: "🌍 Global",
    email: "hello@broadwaymedia.com",
    calendly: "https://calendly.com/broadwaymedia/happytohelp",
    facebook: "https://www.facebook.com/broadwaymedia",
    instagram: "https://www.instagram.com/broadwaymedia/",
    phone: "1-800-277-0343",
    addresses: [
      {
        label: "USA / Canada / Rest of World",
        items: ["105 W 86th Street, #218", "New York, NY 10024"],
      },
    ],
  },
};

/**
 * This is a reverse lookup for the region meta group.
 * Temporary implementation until further development from the backend.
 */
export const ReverseRegionMeta: ReverseRegionMetaGroup = {
  "🇺🇸 United States / 🇨🇦 Canada": {
    region: "🇺🇸 United States",
  },
  "🇦🇺 Australia / 🇳🇿 New Zealand": {
    region: "🇦🇺 Australia",
  },
  "🇬🇧 United Kingdom / 🇪🇺 Europe": {
    region: "🇬🇧 United Kingdom",
  },
  "🌍 Global": {
    region: "🌍 Global",
  },
};

export default RegionMeta;
