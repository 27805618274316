import {
  useHazel,
  useHazelDowngrade,
  useMobile,
} from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { FiDownloadCloud } from "react-icons/fi";
import { TbFlask2 } from "react-icons/tb";
import { Box } from "simple-effing-primitive-layout";

import ReusableSlantedContainer from "../reusable-slanted-container/reusable-slanted-container";

const getVersionNumber = (version: string) => {
  try {
    return parseFloat(
      version.split("-")?.[0].replace("v", "")?.substring(0, 3)
    );
  } catch (error) {
    return 0;
  }
};

export const ReusableDownloadCallToAction = () => {
  const hazel = useHazel();
  const isMobile = useMobile();
  const beta = useHazel({
    beta: true,
  });
  const mobile = useMobile();
  const downgrade = useHazelDowngrade({ major: "1.0.0" });

  if (!hazel.ready) {
    return null;
  }

  const hideBeta = !beta.ready ||
    getVersionNumber(hazel.version) > getVersionNumber(beta.version);

  return (
    <ReusableSlantedContainer
      direction="115deg"
      gradient={["#1529B9", "#1550EE", "#1163E8", "#132F98", "#3001ED"]}
      bottom={0}
      still={isMobile}
    >
      <div className="container">
        <img
          width={mobile ? 150 : 200}
          height={56}
          src="/assets/logo/logo-stage-player.svg"
          alt="StagePlayer"
          loading="lazy"
          style={
            isMobile
              ? { marginBottom: "16px", marginTop: "-80px" }
              : { position: "absolute", top: "-170px" }
          }
        />

        <Row gutter={[38, 18]} align="bottom" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={11}>
            <Rule parse="!hl mb:12 d:block">Get StagePlayer</Rule>
            <Rule parse="!ls mb:12 d:block">
              Get access to perform everywhere with the StagePlayer.
            </Rule>
            <Rule parse="!ls c:#AFAFAF d:block mb:24">
              Latest Version {hazel.version} | Release {hazel.date}
            </Rule>
            <Box
              parse={`
                d:flex ${hideBeta ? "fd:column" : "a:center fw:wrap"}
              `}
              bottom={mobile ? 24 : 96}
              style={{
                rowGap: "12px",
              }}
            >
              <DesignedButton
                properties={{ right: 10 }}
                theme="white"
                label="Download Now"
                press={() => {
                  if (window) {
                    window.open(hazel.download, "_blank");
                  }
                }}
                size="large"
                icon={
                  <FiDownloadCloud
                    size={18}
                    color="#0E1115"
                    title="download icon"
                  />
                }
              />
              {!hideBeta && (
                <DesignedButton
                  properties={{ right: 10 }}
                  theme="tetriary-white"
                  label={`Try Beta | ${beta.version}`}
                  press={() => {
                    if (window) {
                      window.open(beta.download, "_blank");
                    }
                  }}
                  size="large"
                  icon={
                    <TbFlask2 size={18} color="#FFFFFF" title="flask icon" />
                  }
                />
              )}

              {downgrade.ready ? (
                <Rule
                  rule="ls"
                  parse="c:?font2"
                  press={() => {
                    if (window) {
                      window.open(downgrade.url, "_blank");
                    }
                  }}
                >
                  Downgrade to {downgrade.version}
                </Rule>
              ) : undefined}
            </Box>
          </Col>
          <Col xs={24} sm={24} md={12} lg={13}>
            <Box
              parse={`h:${mobile ? "auto" : 0
                } w:100% oa:visible d:flex a:flex-end j:center`}
            >
              <img
                loading="lazy"
                alt="Download Stage Player"
                src="/assets/section/macbook.webp"
                style={{
                  display: "block",
                  width: "100%",
                  height: "auto",
                  pointerEvents: "none",
                }}
              />
            </Box>
          </Col>
        </Row>
      </div>
    </ReusableSlantedContainer>
  );
};

export default ReusableDownloadCallToAction;
