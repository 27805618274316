import { Ecommerce, useShoppingCart } from "@with-nx/ecommerce";
import { Currency, useSubscription } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import AnimatedInfiniteSlantedCards from "../animated-infinite-slanted-cards/animated-infinite-slanted-cards";

export const Element = ({ total }: { total: number }) => {
  const images = [...Array(36)].map((_, i) => `/assets/drops/${i + 1}.jpg`);

  const subscription = useSubscription();
  const region = useServerRegion();

  return (
    <ReusableGlossBox parse="pa:48 mb:24 br:10 c:?foreground p:relative oa:hidden">
      <Box parse="p:relative i:4 z:4">
        <Rule parse="!_t d:block mb:24" tag="h2">
          Interested in unlimited digital backdrops?
        </Rule>
        <Rule parse="!_p d:block mb:24">
          Your current digital drops subtotal exceeds the cost of a monthly
          subscription to our unlimited digital backdrops. By purchasing a
          subscription, you will save{" "}
          <b>
            {Currency.format(
              (total || 0) -
                (subscription?.pricing?.oneTimePurchase?.price || 0),
              region?.currency?.code || "USD"
            )}
          </b>{" "}
          on your current order and gain access to our entire catalog of digital
          drops.
        </Rule>
        <DesignedButton
          label="Subscribe Now"
          press={() => subscription.helpers.create("standard", "otp", true)}
          theme="primary"
          size="medium"
        />
      </Box>
      <Box
        parse="p:absolute t:0 l:0 w:100% h:100% i:1"
        style={{
          backgroundImage:
            "linear-gradient(to bottom, var(--foreground), var(--foreground), transparent)",
        }}
      />
      <Box
        parse="oa:visible p:absolute b:unset r:unset t:50% l:50%"
        style={{ transform: "translateX(-50%) rotate(-15deg)" }}
        id="animated-cards"
      >
        <AnimatedInfiniteSlantedCards size={12} rows={2} images={images} />
      </Box>
    </ReusableGlossBox>
  );
};

export const CheckoutUpgradeToSubscription = () => {
  const subscription = useSubscription();
  const cart = useShoppingCart();
  const drops =
    cart?.data?.data?.nonLicensedItems?.filter(
      (i) => i?.product?.type === "DIGITAL_DROP"
    ) || ([] as Ecommerce.Schemas.NonLicensedItemSchemaType[]);
  const total = drops.reduce(
    (a, b) => a + parseFloat(b?.subtotal?.subtotal || "0"),
    0
  );

  if (
    subscription?.pricing?.oneTimePurchase?.price &&
    total > (subscription?.pricing?.oneTimePurchase?.price || 0) &&
    !subscription.active
  ) {
    return (
      <Box parse="mt:24 mb:24">
        <Element total={total} />
      </Box>
    );
  }
  return null;
};

export default CheckoutUpgradeToSubscription;
