import { useAuth } from "@with-nx/auth";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import Formatter from "../Formatter";
import { isBrowserEnvironment } from "../useService";
import { GatedContentInteractions } from "./GatedContentInteractions";
import { GatedContentUnlock } from "./GatedContentUnlock";

const MONTH_KEY = "last-viewed-month";
const SHOWS_KEY = "viewed-shows";

const DOWNLOADS_KEY = "download-brochure";
const DOWNLOADS_MONTH_KEY = "last-downloaded-month";

const USER_MONTHLY_LIMIT = 10;

export const useGatedShowContent = (showSlug?: string, showId?: string) => {
  const user = useAuth();
  const [blocked, setBlocked] = useState(false);
  const [downloadBlocked, setDownloadBlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [restricted, setRestricted] = useState(false);

  useEffect(() => {
    setRestricted(user?.authState?.status !== "authenticated");
  }, [user?.authState?.status]);

  const resetCountOnNewMonth = (
    lastMonthStored: string | null,
    currentMonthYear: string,
    key: string
  ) => {
    if (lastMonthStored !== currentMonthYear) {
      localStorage.setItem(key, JSON.stringify([]));
      localStorage.setItem(
        key === SHOWS_KEY ? MONTH_KEY : DOWNLOADS_MONTH_KEY,
        currentMonthYear
      );
    }
  };

  const register = useCallback(() => {
    if (blocked || restricted) {
      return;
    }

    if (showSlug && isBrowserEnvironment()) {
      const currentMonthYear = moment().format("YYYY-MM");
      const lastViewedMonth = localStorage.getItem(MONTH_KEY);

      resetCountOnNewMonth(lastViewedMonth, currentMonthYear, SHOWS_KEY);

      const viewedShows = JSON.parse(localStorage.getItem(SHOWS_KEY) || "[]");

      if (!viewedShows.includes(showSlug)) {
        viewedShows.push(showSlug);
        localStorage.setItem(SHOWS_KEY, JSON.stringify(viewedShows));
      }

      GatedContentInteractions.save(
        {
          content: "Scene Previews",
          access: "Unlock",
          metadata: {
            showId: showSlug,
            viewedShows,
          },
        },
        user.authState.session?.token
      );
    }
  }, [showSlug]);

  const registerBrochureDownload = useCallback(() => {
    if (downloadBlocked || restricted) {
      return;
    }

    if (showSlug) {
      const currentMonthYear = moment().format("YYYY-MM");
      const lastDownloadedMonth = localStorage.getItem(DOWNLOADS_MONTH_KEY);

      resetCountOnNewMonth(
        lastDownloadedMonth,
        currentMonthYear,
        DOWNLOADS_KEY
      );

      const downloadedShows = JSON.parse(
        localStorage.getItem(DOWNLOADS_KEY) || "[]"
      );

      if (!downloadedShows.includes(showSlug)) {
        downloadedShows.push(showSlug);
        localStorage.setItem(DOWNLOADS_KEY, JSON.stringify(downloadedShows));
      }

      GatedContentInteractions.save(
        {
          content: "Download brochure",
          access: "Unlock",
          metadata: {
            showId: showSlug,
            downloadedShows,
          },
        },
        user.authState.session?.token
      );
    }
  }, [showSlug, downloadBlocked, restricted]);

  const createWaitList = useCallback(
    async (showId: string) => {
      try {
        setErrorMessage("");
        setSaving(true);
        await GatedContentInteractions.createWaitList(
          String(user.authState.session?.token),
          showId
        );
      } catch (error) {
        let message;

        if (error instanceof Error) message = error.message;
        else message = String(error);

        setErrorMessage(message);
        throw error;
      } finally {
        setSaving(false);
      }
    },
    [showId]
  );

  const leadIsBlockedByKey = (key: string) => {
    const shows = JSON.parse(localStorage.getItem(key) || "[]");
    const count = shows.length;

    const userReachedLimit = count > USER_MONTHLY_LIMIT;
    const userIsLead = !!user.authState.session?.is_lead;

    return userReachedLimit && userIsLead && !Formatter.gup("invite");
  };

  const code = Formatter.gup("invite");

  useEffect(() => {
    setBlocked(leadIsBlockedByKey(SHOWS_KEY));
    setDownloadBlocked(leadIsBlockedByKey(DOWNLOADS_KEY));

    (async () => {
      if (showSlug && code) {
        try {
          setLoading(true);
          const redirect = await GatedContentUnlock.unlock(
            String(code),
            user.authState.session?.token
          );

          if (redirect) {
            setRestricted(false);
            setBlocked(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [user, showSlug, restricted, code]);

  return {
    register,
    registerBrochureDownload,
    createWaitList,
    restricted,
    blocked,
    downloadBlocked,
    loading,
    errorMessage,
    saving,
  };
};
