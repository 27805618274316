import { Formatter, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCardSize,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { CalendlyFormModal, ImageGallery } from "@with-nx/simple-ui/organisms";
import { Products } from "libs/hooks-n-helpers/src/types";
import { FC, useState } from "react";
import { FiPhone } from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

interface LatestDigitalDropsProps {
  drops?: Products;
}

export const LatestDigitalDrops: FC<LatestDigitalDropsProps> = ({ drops }) => {
  const [calendly, _calendly] = useState(false);
  const mobile = useMobile();

  if (!drops?.length) {
    return null;
  }

  return (
    <Box parse={`mb:${mobile ? 48 : 96} ta:center`}>
      <Box>
        <Rule parse="!dm d:block mb:12 ta:center" weight="700" tag="span">
          Discover New Digital Drops
        </Rule>
        <Rule parse="!_p d:block mb:20 ta:center c:?font3">
          With 3,000 animated drops at your fingertips,{" "}
          {mobile ? <br /> : undefined} curate the show collection that unlocks
          your creativity
        </Rule>
      </Box>

      <Box css="d:flex a:flex-start j:center a:center">
        <Box parse="mr:12 mb:12">
          <DesignedButton
            icon={
              <FiPhone size={20} color="var(--primary)" title="phone icon" />
            }
            label="Schedule Consultation"
            theme="tetriary"
            press={() => {
              _calendly(true);
            }}
          />
        </Box>
        <Box parse="mr:12 mb:12">
          <DesignedButton
            label="View All"
            theme="primary"
            href="/digital-backdrops"
          />
        </Box>
      </Box>

      <Box
        parse="mt:32"
        style={{
          maskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
          WebkitMaskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
        }}
      >
        <ImageGallery
          per={3}
          maxItemWidth={500}
          images={drops?.map((item) => {
            return {
              src: Formatter.image(
                item?.attachments?.find(
                  (i: { name: string }) => i.name === "preview_still"
                )?.blob?.signedUrl
              ),
              pop: false,
              size: "16x9" as DesignedCardSize,
            };
          })}
        />
      </Box>

      <CalendlyFormModal
        open={calendly}
        close={() => _calendly(false)}
        submit={() => _calendly(false)}
        origin="Digital Backdrops Landing Page"
      />
    </Box>
  );
};
