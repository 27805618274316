/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuth } from "@with-nx/auth";
import { Formatter, UseCMSHomepage, useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { ReusableGradientHero } from "@with-nx/simple-ui/organisms";
import Image from "next/image";
import React from "react";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

class SlideAutoInterval extends React.PureComponent<
  {
    interval: number;
    disable: boolean;
    length: number;
    render: (slide: number) => React.ReactNode;
  },
  { slide: number }
> {
  interval: NodeJS.Timer | null = null;
  constructor(props: any) {
    super(props);
    this.state = {
      slide: 0,
    };
  }
  override componentDidMount() {
    this.setState({
      slide: 0,
    });
    this.interval = setInterval(() => {
      this.setState({
        slide: this.state.slide + 1,
      });
    }, this.props.interval);
  }
  override componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  override componentDidUpdate(): void {
    if (this.props.disable && this.interval) {
      clearInterval(this.interval);
    }
  }

  override render() {
    return this.props.render(this.state.slide % this.props.length);
  }
}

const heights = [136, 200];

export const HomeSlidersTemplate = ({
  homepage,
}: {
  homepage?: UseCMSHomepage;
}) => {
  const auth = useAuth();
  const [disable] = useState(false);

  const mobile = useMobile();
  return auth?.authState?.session?.user?.id ? (
    <ReusableGradientHero
      backdrop="/assets/hero/homepage-gradient-webp.webp"
      slanted={true}
    >
      <>
        <Rule parse="!_t d:block mb:12" tag="h2">
          Welcome, {auth.authState.session.user.name}!
        </Rule>
        <Rule parse="!_p d:block mb:32">
          What show are you looking for today?
        </Rule>
        <DesignedButton
          label="Search Shows"
          theme="white"
          size="large"
          href="/search"
        />
      </>
    </ReusableGradientHero>
  ) : (
    <SlideAutoInterval
      length={homepage?.slides?.length || 0}
      interval={10000}
      disable={disable || mobile}
      render={(slide) => (
        <ReusableGradientHero
          slanted={true}
          src={
            homepage?.slides?.[slide]?.coverImage
              ? Formatter.compressed(homepage.slides?.[slide]?.coverImage, 1024)
              : undefined
          }
          backdrop={"/assets/hero/homepage-gradient-webp.webp"}
          slide={slide}
          right={
            homepage?.slides?.[slide]?.logoImage && !mobile ? (
              <Box css="d:flex a:center j:flex-end oa:visible">
                <Image
                  src={Formatter.compressed(
                    homepage?.slides?.[slide]?.logoImage,
                    256
                  )}
                  alt={homepage?.slides?.[slide]?.title || "Broadway Media"}
                  width={mobile ? 128 : 320}
                  height={heights?.[slide] || 200}
                  objectFit="contain"
                  priority
                />
              </Box>
            ) : undefined
          }
          slides={homepage?.slides?.map((slide) => (
            <>
              <Rule parse="!_t d:block mb:12" tag="h2">
                {slide.title}
              </Rule>
              <Rule parse="!_p d:block mb:32">{slide.description}</Rule>
              <DesignedButton
                label={slide.buttonText}
                theme="white"
                size="large"
                href={slide.buttonURL}
              />
            </>
          ))}
        />
      )}
    />
  );
};

export default HomeSlidersTemplate;
