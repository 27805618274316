import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface DropdownMenuProps {
  label: string;
  description?: string;
  options: {
    hidden?: boolean;
    label?: string;
    url?: string;
    icon?: unknown;
    options?: {
      label: string;
      url?: string;
      icon?: unknown;
    }[];
  }[];
  mobile?: boolean;
  image?: string;
  chunks?: number;
}

export const DropdownMenu = ({
  label,
  options,
  description,
  mobile,
  image,
  chunks = 2,
}: DropdownMenuProps) => {
  const [open, _open] = useState(mobile ? true : false);
  const router = useRouter();

  const handle = (url?: string) => {
    if (!url) {
      return;
    }

    if (url.includes("https://") || url.includes("http://")) {
      if (url.includes(window.location.origin)) {
        router.push(url);
      } else {
        window.open(url, "_blank");
      }
    } else {
      router.push(url);
    }
  };

  const _chunks = (() => {
    if (options.length === chunks) {
      return [...options?.map((option) => [option])];
    }

    const t = [];
    for (let i = 0; i < options.length; i += chunks) {
      const chunk = options.slice(i, i + chunks);
      t.push(chunk);
    }

    return t;
  })();

  return (
    <div
      onClick={!mobile ? undefined : () => _open(!open)}
      onMouseEnter={mobile ? undefined : () => _open(true)}
      onMouseLeave={mobile ? undefined : () => _open(false)}
    >
      <Box css="p:relative">
        <Box
          parse="d:inline-flex pt:8 pb:8 pl:16 pr:16 d:inline-flex a:center br:5"
          color="transparent"
          native={{
            cypress: `nav-${label}`,
            id: `nav-${label}`,
          }}
        >
          <Rule
            parse="!ls d:block"
            color="white"
            display="block"
            style={{ whiteSpace: "nowrap" }}
          >
            {label}
          </Rule>
          <Box parse="ml:10 w:16 h:16 d:inline-flex a:center j:center">
            {open ? (
              <DesignedIcon size={16} color="#AFAFAF" name="up/regular" />
            ) : (
              <DesignedIcon size={16} color="#AFAFAF" name="down/regular" />
            )}
          </Box>
        </Box>
        {open ? (
          <Box
            parse={
              mobile
                ? "p:relative d:flex fd:column"
                : "p:absolute l:0 b:unset r:unset t:100% pt:10 z:98 i:98"
            }
          >
            {mobile ? undefined : (
              <Box parse="p:absolute r:unset b:unset t:1 l:48">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00047 0.5L13.9287 9.5H0.0722656L7.00047 0.5Z"
                    fill="#1E242D"
                  />
                </svg>
              </Box>
            )}
            <Box
              parse="d:flex a:flex-start pa:8 br:10 c:?foreground"
              direction={mobile ? "column" : "row"}
            >
              {mobile ? undefined : (
                <Box css="d:inline-flex a:center j:center p:relative">
                  <Box parse="pa:16 p:absolute t:0 l:0 w:100%">
                    <Rule parse="!ll d:block mb:0">{label}</Rule>
                    <Rule parse="!ls d:block mb:0 c:#E1E1E1">
                      {description}
                    </Rule>
                  </Box>
                  {image ? (
                    <Box parse="w:236">
                      <img
                        alt={label}
                        src={image}
                        style={{
                          borderRadius: 5,
                          overflow: "hidden",
                          width: 236,
                          height: "auto",
                          pointerEvents: "none",
                          display: "block",
                        }}
                        loading="lazy"
                      />
                    </Box>
                  ) : (
                    <svg
                      width="236"
                      height="207"
                      viewBox="0 0 236 207"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2192_167663)">
                        <rect width="236" height="207" rx="5" fill="#2D3542" />
                        <circle
                          cx="208"
                          cy="208"
                          r="196"
                          transform="matrix(-1 0 0 1 329 106)"
                          stroke="white"
                          stroke-opacity="0.03"
                          stroke-width="24"
                        />
                        <circle
                          cx="164.338"
                          cy="164.338"
                          r="152.338"
                          transform="matrix(-1 0 0 1 282.699 149.875)"
                          stroke="white"
                          stroke-opacity="0.03"
                          stroke-width="24"
                        />
                        <path
                          d="M4.62019 313.788C4.62019 375.092 54.5781 424.89 116.329 424.89C178.079 424.89 228.037 375.092 228.037 313.788C228.037 252.483 178.079 202.686 116.329 202.686C54.5781 202.686 4.62019 252.483 4.62019 313.788Z"
                          stroke="white"
                          stroke-opacity="0.03"
                          stroke-width="24"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2192_167663">
                          <rect width="236" height="207" rx="5" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </Box>
              )}

              <Box
                parse="d:flex"
                style={{
                  padding: mobile ? 8 : 16,
                  flexDirection: mobile ? "column" : "row",
                }}
              >
                {_chunks.map((options, c) => (
                  <Box key={c} parse="pa:8">
                    {options.map((option, o) =>
                      option.hidden ? null : (
                        <Box
                          parse={
                            mobile ? "d:flex ml:12 mb:20" : "d:flex mb:24 ml:12"
                          }
                          style={{
                            marginBottom:
                              o === options?.length - 1 ? 0 : mobile ? 20 : 24,
                          }}
                          align={option.options ? "flex-start" : "center"}
                          key={o}
                        >
                          {option.icon ? (
                            <Box
                              parse="h:36 w:36 d:inline-flex a:center j:center br:999 c:?accent mr:12"
                              style={{ minWidth: 36 }}
                            >
                              {option.icon}
                            </Box>
                          ) : undefined}
                          <Box>
                            {option.label ? (
                              <Rule
                                parse="!lt d:block"
                                color={option.options ? "#59687B" : "white"}
                                bottom={option.options ? 8 : 0}
                                display="block"
                                press={
                                  option.url
                                    ? () => handle(option.url)
                                    : undefined
                                }
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {option.label.toUpperCase()}
                              </Rule>
                            ) : undefined}
                            {option.options?.map((sub, s) => (
                              <Box parse="d:flex a:center pt:8 pb:8" key={s}>
                                {sub.icon ? (
                                  <Box
                                    parse="w:32 h:32 br:5 d:inline-flex a:center j:center mr:12"
                                    style={{
                                      backgroundImage:
                                        "linear-gradient(117.32deg, #6609FF -8.26%, #00D1FF 122.28%)",
                                    }}
                                  >
                                    {sub.icon}
                                  </Box>
                                ) : undefined}
                                <Rule
                                  parse="!ls d:block"
                                  color="white"
                                  display="block"
                                  press={() => handle(sub.url)}
                                  style={{ whiteSpace: "nowrap" }}
                                  native={{
                                    cypress: `nav-item-${sub.label}`,
                                    id: `nav-item-${sub.label}`,
                                  }}
                                >
                                  {sub.label}
                                </Rule>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ) : undefined}
      </Box>
    </div>
  );
};

export default DropdownMenu;
