import { Box } from "simple-effing-primitive-layout";

import Colors from "./Colors";
import Text from "./Text";

interface Props {
  label: string;
  children?: React.ReactNode;
  inside?: React.ReactNode;
  position: string;
  properties?: {
    [key: string]: unknown;
  };
  width?: number;
  textAlign?: string;
}

const Tooltip = ({
  label,
  children,
  position = "top-right",
  properties = {},
  inside = null,
  width = 256,
  textAlign = "left",
}: Props) => {
  let styles = {};

  switch (position) {
    case "top-left":
      styles = {
        left: "calc(50% - 20px)",
        top: "calc(100% + 5px)",
        right: "unset",
        bottom: "unset",
      };
      break;

    case "bottom-right":
      styles = {
        right: "calc(50% - 20px)",
        bottom: "calc(100% + 5px)",
        left: "unset",
        top: "unset",
      };
      break;

    case "bottom-left":
      styles = {
        left: "calc(50% - 20px)",
        bottom: "calc(100% + 5px)",
        right: "unset",
        top: "unset",
      };
      break;

    case "top-center":
      styles = {
        left: "50%",
        transform: "translateX(-50%)",
        top: "calc(100% + 5px)",
        right: "unset",
        bottom: "unset",
      };
      break;
    case "bottom-center":
      styles = {
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "calc(100% + 5px)",
        right: "unset",
        top: "unset",
      };
      break;

    default:
      styles = {
        right: "calc(50% - 20px)",
        top: "calc(100% + 5px)",
        left: "unset",
        bottom: "unset",
      };
      break;
  }

  return (
    <Box
      css={"tip --" + position}
      parse="p:relative"
      style={{ overflow: "visible" }}
      {...properties}
    >
      <Box
        css="--tip"
        parse={`w:${width} pa:5 br:5 p:absolute`}
        mode="position"
        color="rgba(68,68,68,0.85)"
        style={{
          filter: "drop-shadow(0px 4px 10px rgba(30, 30, 30, 0.3))",
          ...styles,
        }}
      >
        <Text
          rule="Label Small"
          align={textAlign}
          color={Colors.font1}
          display="block"
        >
          {label}
        </Text>
        {inside || null}
      </Box>
      {children}
    </Box>
  );
};

export default Tooltip;
