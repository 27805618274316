import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useMobile } from "@with-nx/hooks-n-helpers";
import { FC, ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { FiLoader, FiPlay } from "react-icons/fi";
import ReactPlayer from "react-player/lazy";
import { Box } from "simple-effing-primitive-layout";

import PlaceholderImage from "../placeholder-image/placeholder-image";
import Rule from "../rule/rule";
import { InsideCard } from "./inside-card";
import { OutsideCard } from "./outside-card";
import Image from "next/image";

export type DesignedCardPrice = {
  undiscounted?: string;
  price: string;
};

export type DesignedCardCart = {
  added: boolean;
  authenticated?: boolean;
  press?: () => void;
  properties?: {
    [key: string]: unknown;
  };
  cypressTitle?: string;
};

type DesignedCardGradient = {
  direction:
    | "right"
    | "left"
    | "top"
    | "bottom"
    | "top-bottom"
    | "left-right"
    | "cover";
  color?: string;
};

interface DesignedCardInsideProps {
  title?: string;
  subtitle?: string;
  branding?:
    | "stage-guide"
    | "stage-player"
    | "stage-gear"
    | "musical-theatre-international"
    | "theatrical-rights-worldwide"
    | "broadway-licensing";
  large?: string;
  extraLarge?: string;
  cart?: DesignedCardCart;
  price?: DesignedCardPrice;
  tags?: string[];
  tagPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  controlsPosition?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  image?: string;
  imageFit?: string;
  color?: string;
  press?: () => unknown;
  controls?: JSX.Element[];
  alt?: string;
  labelTextShadow?: boolean;
}

interface DesignedCardOutsideProps {
  title?: string;
  subtitle?: ReactNode;
  children?: ReactNode;
  price?: DesignedCardPrice;
  tags?: string[];
  cart?: DesignedCardCart;
  press?: () => unknown;
  properties?: {
    [key: string]: unknown;
  };
  mode?: "default" | "redirect";
}

interface ReactPlayerWrapperProps {
  children: ReactNode;
  alt?: string;
}

const ReactPlayerWrapper: FC<ReactPlayerWrapperProps> = ({ children, alt }) => (
  <div style={{ height: "100%" }} aria-label={alt}>
    {children}
  </div>
);

export type DesignedCardSize = "square" | "16x9" | "16x5" | "12x16" | "16x12";

interface DesignedCardProps {
  inside?: DesignedCardInsideProps;
  outside?: DesignedCardOutsideProps;
  image?: string;
  alt?: string;
  video?: string;
  embed?: string;
  size?: DesignedCardSize;
  gradient?: DesignedCardGradient;
  properties?: {
    [key: string]: unknown;
  };
  native?: {
    [key: string]: unknown;
  };
  pop?: boolean;
  renderPop?: () => void;
  mode?: "contain" | "cover";
  blur?: boolean;
  muted?: boolean;
  playing?: boolean;
  loop?: boolean;
  controls?: boolean;
  playable?: boolean;
  watermark?: boolean;
  meta?: [string, string][];
  href?: string;
  origin?: string;
  placeholder?: boolean;
  newImage?: boolean;
}

const useVideoProgress = (
  ref: RefObject<HTMLVideoElement>,
  hover?: boolean
) => {
  const [data, _data] = useState({
    duration: Infinity,
    current: 0,
    progress: 0,
  });

  useEffect(() => {
    let timeout: NodeJS.Timer | undefined = undefined;

    if (ref.current && hover) {
      timeout = setInterval(() => {
        if (!ref.current) {
          return;
        }

        if (ref.current.src === "") {
          _data({
            duration: Infinity,
            current: 0,
            progress: 0,
          });
        } else {
          _data({
            duration: ref.current.duration,
            current: ref.current.currentTime,
            progress: (ref.current.currentTime / ref.current.duration) * 100,
          });
        }
      }, 20);
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [ref.current, hover]);

  return data;
};

const DesignedCardVideo = ({
  video,
  image,
  playable,
  mode,
  watermark,
  hover,
}: {
  video?: string;
  image?: string;
  mode?: "fill" | "contain" | "cover";
  playable?: boolean;
  watermark?: boolean;
  hover?: boolean;
}) => {
  const ref = useRef<HTMLVideoElement>(null);
  const progress = useVideoProgress(ref, hover);
  const [loading, _loading] = useState(true);

  if (!video) {
    return null;
  }

  useEffect(() => {
    if (ref.current) {
      if (hover) {
        if (ref.current) {
          ref.current.onloadeddata = () => {
            if (ref.current) {
              if (ref.current && ref.current.duration >= 10) {
                ref.current.currentTime = 5;
              }

              ref.current.play();
            }

            _loading(false);
          };
          ref.current.src = video;
          ref.current.load();
        }
      } else {
        _loading(true);
        ref.current.pause();
        ref.current.src = "";
        if (ref.current.duration >= 10) {
          ref.current.currentTime = 5;
        } else {
          ref.current.currentTime = 0;
        }
      }
    }
  }, [hover]);

  return (
    <>
      {hover && loading ? (
        <Box
          css="w:100% h:100% br:5 oa:hidden t:0 l:0 p:absolute d:block"
          layer={2}
        >
          <Box
            css="w:100% h:100% br:5 oa:hidden t:0 l:0 p:absolute d:flex a:center j:center"
            layer={3}
          >
            <FiLoader className="--spinner" size={24} color="white" />
          </Box>
        </Box>
      ) : undefined}
      {hover ? undefined : (playable || watermark) && image && video ? (
        <Box
          css="w:100% h:100% br:5 oa:hidden t:0 l:0 p:absolute d:block"
          layer={2}
        >
          <Box
            css="w:100% h:100% br:5 oa:hidden t:0 l:0 p:absolute d:flex a:center j:center"
            layer={3}
          >
            <FiPlay size={24} color="white" fill="white" title="play icon" />
          </Box>
        </Box>
      ) : undefined}
      <video
        id={video}
        src={video}
        poster={image || undefined}
        muted
        loop
        ref={ref}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 5,
          overflow: "hidden",
          objectFit: mode,
        }}
        preload="none"
      />
      {video && playable && hover && !loading ? (
        <Box
          css="w:100% p:absolute t:unset b:0 l:0 r:0 z:3 i:3"
          parse="h:4 c:?foreground"
        >
          <Box parse="h:4 d:block c:?primary" width={`${progress.progress}%`} />
        </Box>
      ) : undefined}
    </>
  );
};

export const DesignedCard: React.FC<DesignedCardProps> = ({
  inside,
  outside,
  properties,
  size,
  image,
  alt,
  video,
  embed,
  gradient,
  pop,
  renderPop,
  mode = "cover",
  blur,
  muted = true,
  playing = true,
  loop = true,
  controls = false,
  placeholder = false,
  newImage = false,
  meta,
  href,
  native,
  playable,
  watermark,
}) => {
  const [open, _open] = useState(false);
  const [hover, _hover] = useState(false);

  const _gradient: string = (() => {
    if (!gradient) {
      return "unset";
    }

    const color = gradient.color || "#1E242D";

    if (gradient.direction === "cover") {
      return `linear-gradient(to top, ${color}, ${color})`;
    }

    if (gradient.direction === "top-bottom") {
      return `linear-gradient(to top, ${color}, transparent, transparent, ${color})`;
    }

    if (gradient.direction === "left-right") {
      return `linear-gradient(to right, ${color}, transparent, transparent, ${color})`;
    }

    const _directions = {
      left: "right",
      right: "left",
      top: "bottom",
      bottom: "top",
    };

    return `linear-gradient(to ${
      _directions[gradient.direction]
    }, ${color}, transparent, transparent)`;
  })();

  const mobile = useMobile();

  const Element = href ? `a` : `div`;

  return (
    <>
      {pop ? (
        <Modal
          onClose={() => _open(false)}
          closeOnOverlayClick={true}
          isOpen={open}
        >
          <ModalOverlay />
          <ModalContent
            className="rounded-brand"
            style={{ backgroundColor: "transparent" }}
          >
            {video ? (
              <video
                src={video}
                loop={loop}
                autoPlay={playing}
                muted={muted}
                controls={controls}
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                  objectFit: mode,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (pop) {
                    _open(true);
                  }
                }}
              />
            ) : image ? (
              <img
                loading="lazy"
                alt={
                  outside?.title ||
                  inside?.title ||
                  meta?.[0]?.[0] ||
                  "Broadway Media"
                }
                src={image}
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                  objectFit: mode,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (pop) {
                    _open(true);
                  }
                }}
              />
            ) : (
              <PlaceholderImage
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                }}
              />
            )}
            {meta?.length ? (
              <Box parse="mt:24 pa:16 br.5 c:?foreground">
                {meta?.map((item) => (
                  <>
                    <Rule parse="!lt c:?font1 d:block mb:4">{item[1]}</Rule>
                  </>
                ))}
              </Box>
            ) : undefined}
          </ModalContent>
        </Modal>
      ) : undefined}

      <Element
        {...{
          onMouseEnter: () => {
            _hover(true);
          },
          onMouseLeave: () => {
            _hover(false);
          },
        }}
        {...(href
          ? {
              href,
              rel:
                href?.includes("http") && !href?.includes("broadwaymedia")
                  ? "nofollow"
                  : "noreferrer",
            }
          : {})}
        {...native}
      >
        <Box css="d:block" {...properties}>
          <Box
            mode="padding"
            top={
              size === "16x5"
                ? "31.25%"
                : size === "12x16"
                ? "133%"
                : size === "16x12"
                ? "75%"
                : size === "16x9"
                ? "56.25%"
                : "100%"
            }
            css={`w:100% p:relative br:5 oa:visible`}
          >
            {_gradient !== "unset" ? (
              <Box
                parse="w:100% h:100% br:5 oa:hidden p:absolute t:0 l:0 z:2 i:2"
                style={{
                  backgroundImage: _gradient,
                  backdropFilter: blur ? "blur(10px)" : "none",
                }}
                press={() => {
                  if (pop) {
                    _open(true);
                  }
                }}
              />
            ) : undefined}
            {!mobile && Number(meta?.length) > 0 ? (
              <Box
                parse="w:100% h:100% br:5 oa:hidden p:absolute pa:10 t:0 l:0 z:2 i:2 d:flex j:flex-end fd:column"
                style={{
                  backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.2), transparent)`,
                }}
                press={() => {
                  if (pop) {
                    _open(true);
                  }
                }}
              >
                {meta?.map((item) => (
                  <>
                    <Rule parse="!lt c:?font1 d:block mb:4 ta:right">
                      {item[1]}
                    </Rule>
                  </>
                ))}
              </Box>
            ) : undefined}

            {embed || video || image || placeholder ? (
              <Box
                parse="w:100% h:100% br:5 oa:hidden t:0 l:0 p:absolute c:black d:block"
                layer={embed ? 99 : undefined}
              >
                {(() => {
                  if (embed) {
                    return (
                      <ReactPlayer
                        url={embed}
                        loop={loop}
                        playing={playing}
                        muted={muted}
                        controls={controls}
                        width="100%"
                        height="100%"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 5,
                          overflow: "hidden",
                          objectFit: mode,
                        }}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 0 },
                          },
                        }}
                        loading="lazy"
                        alt={alt}
                        wrapper={ReactPlayerWrapper}
                      />
                    );
                  } else if (video) {
                    return (
                      <DesignedCardVideo
                        hover={hover}
                        image={image}
                        mode={mode}
                        watermark={watermark}
                        video={video}
                        playable={playable}
                      />
                    );
                  } else if (image) {
                    if (newImage) {
                      return (
                        <div
                          style={{
                            width: "100%",
                            height: "auto",
                            display: "block",
                            borderRadius: 5,
                            overflow: "hidden",
                            objectFit: mode,
                            cursor: "pointer",
                          }}
                        >
                          <Image
                            loading="lazy"
                            alt={
                              outside?.title ||
                              inside?.title ||
                              meta?.[0]?.[0] ||
                              "Broadway Media"
                            }
                            src={image}
                            layout="fill"
                            onClick={() => {
                              if (pop) {
                                _open(true);
                              }
                            }}
                          />
                        </div>
                      );
                    }

                    return (
                      <img
                        loading="lazy"
                        alt={
                          alt ||
                          outside?.title ||
                          inside?.title ||
                          meta?.[0]?.[0] ||
                          "Broadway Media"
                        }
                        onClick={() => {
                          if (pop) {
                            _open(true);
                          }
                        }}
                        src={image}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 5,
                          overflow: "hidden",
                          objectFit: mode,
                        }}
                      />
                    );
                  }

                  return (
                    <PlaceholderImage
                      style={{ width: "100%", height: "auto" }}
                    />
                  );
                })()}
              </Box>
            ) : undefined}

            {inside ? (
              <InsideCard
                {...inside}
                click={(event) => {
                  const target = event.target as HTMLDivElement;

                  if (
                    target.classList.contains("--cart") ||
                    target.classList.contains("--icon")
                  ) {
                    return;
                  }

                  if (pop) {
                    renderPop ? renderPop() : _open(true);
                  }

                  if (inside?.press) {
                    inside.press();
                  }
                }}
              />
            ) : undefined}
          </Box>
          {outside ? <OutsideCard {...outside} /> : undefined}
        </Box>
      </Element>
    </>
  );
};

export default DesignedCard;
