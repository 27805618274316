import Moment from "moment";

export class CalendarUtils {
  static today(): string {
    return Moment().format("YYYY-MM-DD");
  }

  static year(date: string): string {
    const _date = Moment(date);
    if (_date.isValid()) {
      return _date.format("YYYY");
    }
    return Moment().format("YYYY");
  }

  static month(date: string): string {
    const _date = Moment(date);
    if (_date.isValid()) {
      return _date.format("M");
    }
    return Moment().format("M");
  }

  static years(): [string, string][] {
    /* Returns available years. */
    const year = parseInt(Moment().format("YYYY"));

    const years: [string, string][] = [];
    for (let index = 0; index < 5; index++) {
      years.push([String(year + index), String(year + index)]);
    }

    return years;
  }

  static months(): [string, string][] {
    /* Returns available months. */

    const months: [string, string][] = [];
    for (let index = 1; index <= 12; index++) {
      months.push([String(index), Moment(index, "M").format("MMMM")]);
    }

    return months;
  }

  static calendar(date: string): (null | { day: number; date: string })[][] {
    const currentMonthAndYear = Moment(date).format("YYYY-MM-");
    const totalDaysInMonth = Moment(date).daysInMonth();
    const startOfMonth = Moment(date).startOf("month");
    const endOfMonth = Moment(date).endOf("month");

    const data: (null | { day: number; date: string })[] = [];

    if (startOfMonth.isoWeekday() > 1) {
      for (let i = 0; i < startOfMonth.weekday() - 1; i++) {
        data.push(null);
      }
    }

    for (let i = 0; i < totalDaysInMonth; i++) {
      data.push({
        day: i + 1,
        date: Moment(currentMonthAndYear + (i + 1), "YYYY-MM-D").format(
          "YYYY-MM-DD"
        ),
      });
    }

    if (endOfMonth.isoWeekday() < 7) {
      for (let i = 0; i < 7 - endOfMonth.weekday(); i++) {
        data.push(null);
      }
    }

    return Array.from({ length: 6 }, () => data.splice(0, 7));
  }

  static between(date: string, from: string, to: string): boolean {
    return date > from && date < to;
  }

  static active(date: string, value?: string[]): boolean {
    if (value?.includes(date)) {
      return true;
    }

    if (value?.length === 2) {
      return this.between(date, value[0], value[1]);
    }

    return false;
  }

  static mode(
    date: string,
    value?: string[]
  ): "circle" | "middle" | "start" | "end" | "none" {
    if (value?.length === 2) {
      if (date === value[0]) {
        return "start";
      }

      if (date === value[1]) {
        return "end";
      }

      if (this.between(date, value[0], value[1])) {
        return "middle";
      }
    }

    if (value?.length === 1) {
      if (value?.includes(date)) {
        return "circle";
      }
    }

    return "none";
  }
}

export default CalendarUtils;
