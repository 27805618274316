import { useAuth } from "@with-nx/auth";
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import ProfileSidebar from "./profile";

export const MenuSidebar = ({
  open = true,
  close,
}: {
  open?: boolean;
  close?: () => unknown;
}) => {
  const { authState } = useAuth();
  const name = authState?.session?.user.name.split(" ")[0];

  if (!open) {
    return null;
  }

  return (
    <Box
      parse="p:fixed h:100vh w:100% r:0 t:0 b:unset l:unset c:?foreground z:99 i:99"
      style={{ maxWidth: 450 }}
    >
      <Box
        parse="pa:24 d:flex a:center j:space-between"
        style={{ borderBottom: "1px solid #2D3542" }}
      >
        <Rule parse="!ll">Hey, {name}</Rule>
        <Box css="d:inline-flex a:center">
          <DesignedIcon
            name="multiply/bold"
            color="var(--font4)"
            press={close}
          />
        </Box>
      </Box>
      <ProfileSidebar
        email={authState.session?.user.email}
        name={authState.session?.user.name}
        close={close}
      />
    </Box>
  );
};

export default MenuSidebar;
