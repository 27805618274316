/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DebounceContainer,
  DesignedButton,
  DesignedInput,
} from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const GlobalSearchInput = ({
  size = "large",
  properties = { border: "unset", color: "transparent" },
  reference,
  blur,
  withButton,
}: {
  size?: "large" | "medium" | "small";
  properties?: {
    [key: string]: unknown;
  };
  reference?: React.RefObject<HTMLInputElement>;
  blur?: () => void;
  focus?: () => void;
  withButton?: boolean;
}) => {
  const router = useRouter();

  const [search, _search] = useState("");
  const [s, _s] = useState("");

  const [focused, _focused] = useState(false);

  const redirectSearch = () => {
    router.push(
      `/search${s.trim() ? `?s=${encodeURIComponent(s.trim())}` : ""}`
    );
  };

  return (
    <>
      <Box css="p:relative f:1 oa:visible p:relative i:99 z:99">
        <DebounceContainer
          value={search}
          change={(search) => _search(search || "")}
          delay={0}
          render={({ value, set }) => (
            <DesignedInput
              bottom={0}
              size={size}
              icon="search/bold"
              placeholder="Search for your Show"
              container={{ flex: 1 }}
              properties={properties}
              value={value}
              change={(value) => {
                set(value);
                _s(value);
              }}
              blur={() => {
                if (blur) {
                  blur();
                }
                _focused(false);
              }}
              focus={() => {
                if (focus) {
                  focus();
                }
                _focused(true);
              }}
              color={focused ? "var(--primary)" : undefined}
              reference={reference}
              native={{
                onKeyDown: (event: KeyboardEvent) => {
                  if (event.key === "Enter") {
                    redirectSearch();
                  }
                },
                cypress: "global-search-input",
                name: "search",
              }}
            />
          )}
        />

        {/*
            search.length && products?.result?.length ? (
          <Box
            parse="p:absolute b:unset r:unset l:0 br:10 c:#2D3542 pa:16 w:100% h:auto i:999 z:999"
            mode="position"
            top="calc(100% + 12px)"
          >
            <Rule parse="!lt mb:4 c:?font4 d:block">Suggested</Rule>
            {products?.result?.map((product) => {
              const _image = (product as unknown as any)?.attachments?.find(
                (i: any) => i?.name === "logo"
              )?.blob?.signedUrl;

              return (
                <>
                  <Box
                    parse="d:flex a:flex-start h:54 mb:2"
                    element="a"
                    native={{
                      href: `/shows/${
                        product?.showBrand?.slug || product?.slug
                      }/${product.slug}`,
                      rel: "noreferrer",
                    }}
                  >
                    <Box
                      parse="br:8 h:32 w:32 c:#1E242D mr:12"
                      style={{ minWidth: 32 }}
                    >
                      <DesignedCard
                        size="square"
                        image={_image}
                        mode="contain"
                      />
                    </Box>
                    <Box>
                      <Rule parse="!ls d:block mb:3">{product.title}</Rule>
                      <DesignedTag
                        size="small"
                        label="Show"
                        background="#1E242D"
                        color="white"
                      />
                    </Box>
                  </Box>
                  {(product as unknown as any).choreoGuide ? (
                    <Box
                      parse="d:flex a:flex-start h:54 mb:2"
                      element="a"
                      native={{
                        href: `/shows/${product.slug}/${product.slug}/choreography-guides`,
                        rel: "noreferrer",
                      }}
                    >
                      <Box
                        parse="br:8 h:32 w:32 c:#1E242D mr:12"
                        style={{ minWidth: 32 }}
                      >
                        <DesignedCard
                          size="square"
                          image={_image}
                          mode="contain"
                        />
                      </Box>
                      <Box>
                        <Rule parse="!ls d:block mb:3">{product.title}</Rule>
                        <DesignedTag
                          size="small"
                          label="Choreography Guides"
                          background="#1E242D"
                          color="white"
                        />
                      </Box>
                    </Box>
                  ) : undefined}
                  {product.scenicProjections.length
                    ? product.scenicProjections.map((projection) =>
                        !projection.public ? null : (
                          <Box
                            parse="d:flex a:flex-start h:54 mb:2"
                            element="a"
                            native={{
                              href: `/shows/${product.slug}/${product.slug}/scenic-projections`,
                              rel: "noreferrer",
                            }}
                          >
                            <Box
                              parse="br:8 h:32 w:32 c:#1E242D mr:12"
                              style={{ minWidth: 32 }}
                            >
                              <DesignedCard
                                size="square"
                                image={_image}
                                mode="contain"
                              />
                            </Box>
                            <Box>
                              <Rule parse="!ls d:block mb:3">
                                {product.title} {projection.name}
                              </Rule>
                              <DesignedTag
                                size="small"
                                label="Scenic Projections"
                                background="#1E242D"
                                color="white"
                              />
                            </Box>
                          </Box>
                        )
                      )
                    : undefined}
                </>
              );
            })}
          </Box>
        ) : undefined
          */}
      </Box>

      {withButton && (
        <DesignedButton
          label="Search"
          size="large"
          theme="primary"
          press={() => {
            redirectSearch();
          }}
        />
      )}
    </>
  );
};

export default GlobalSearchInput;
