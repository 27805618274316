import { useMobile } from "@with-nx/hooks-n-helpers";
import { Col, Row } from "antd";
import { ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";

export const GridStepScroller = ({
  xs,
  sm,
  md,
  lg,
  xl,
  items,
  bypass,
  per = 1,
  gutter = [36, 18],
  desktop,
  inside,
  justify = "start",
}: {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  items: ReactNode[];
  bypass?: number;
  per?: number;
  gutter?: [number, number];
  desktop?: boolean | JSX.Element;
  inside?: boolean;
  justify?:
    | "start"
    | "end"
    | "center"
    | "space-around"
    | "space-between"
    | "space-evenly";
}) => {
  const mobile = useMobile();

  const _width =
    bypass && !mobile
      ? `calc((100vw - ${(bypass - 1) * 30}px) / ${bypass})`
      : `calc((100vw / ${per || 1}) - 30px)`;
  const _min =
    bypass && !mobile
      ? `calc((100vw - ${(bypass - 1) * 58}px) / ${bypass})`
      : `calc((100vw / ${per || 1}) - ${bypass || 60}px)`;

  return (
    <>
      <MobileViewSwitcher
        render={(mobile) =>
          mobile || bypass ? (
            <div
              className={inside ? "" : "container"}
              style={
                desktop && !mobile
                  ? {
                      overflow: "visible",
                      marginRight: 0,
                      marginLeft: 0,
                      width: "100%",
                    }
                  : {}
              }
            >
              <Box
                parse={`pl:15 pr:15 ox:${
                  desktop && !mobile ? "auto" : "auto"
                } ml:-15 mr:-15 d:flex`}
                css="--no-scrollbar"
                style={{ scrollSnapType: "x mandatory" }}
                id="grid-step-scroller"
              >
                {desktop && !mobile ? (
                  <div className="container-blob" />
                ) : undefined}
                {items
                  .filter((i) => i)
                  .map((item, index) =>
                    !item ? null : (
                      <Box
                        key={index}
                        parse="mr:15"
                        width={_width}
                        style={{ minWidth: _min }}
                      >
                        {item}
                      </Box>
                    )
                  )}
                {desktop && !mobile ? (
                  <div className="container-blob" />
                ) : undefined}
              </Box>
            </div>
          ) : (
            <div className={inside ? "" : "container"}>
              <Row gutter={gutter} justify={justify}>
                {items
                  .filter((i) => i)
                  .map((item, index) =>
                    !item ? null : (
                      <Col key={index} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                        {item}
                      </Col>
                    )
                  )}
              </Row>
            </div>
          )
        }
      />
      {desktop ? (
        <Box css="d:flex a:center j:space-between container">
          {!mobile ? (
            <Box css="d:flex a:center j:flex-end" parse="mt:24">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 20, cursor: "pointer" }}
                onClick={() => {
                  const element = document.getElementById("grid-step-scroller");
                  if (element) {
                    element.scrollTo({
                      left:
                        element.scrollLeft -
                        element.clientWidth / (bypass || 1),
                      behavior: "smooth",
                    });
                  }
                }}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                  fillOpacity="0.3"
                />
                <path
                  d="M22.6759 13.927C22.6759 14.0733 22.6191 14.2193 22.5063 14.3298L16.6853 20.0094L22.4942 25.6774C22.7169 25.8942 22.7209 26.2506 22.504 26.4728C22.2872 26.6952 21.9309 26.6997 21.7084 26.4826L15.4869 20.4121C15.27 20.2 15.27 19.819 15.4869 19.6069L21.7205 13.5243C21.9429 13.3075 22.299 13.3117 22.5161 13.5341C22.6227 13.6435 22.6759 13.7853 22.6759 13.927Z"
                  fill="white"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#AFAFAF"
                />
              </svg>
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const element = document.getElementById("grid-step-scroller");
                  if (element) {
                    element.scrollTo({
                      left:
                        element.scrollLeft +
                        element.clientWidth / (bypass || 1),
                      behavior: "smooth",
                    });
                  }
                }}
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                  fillOpacity="0.3"
                />
                <path
                  d="M17.3241 26.0799C17.3241 25.9337 17.3809 25.7877 17.4937 25.6772L23.3147 19.9976L17.5058 14.3296C17.2831 14.1127 17.2791 13.7564 17.496 13.5342C17.7128 13.3117 18.0691 13.3072 18.2916 13.5243L24.5131 19.5948C24.73 19.8069 24.73 20.188 24.5131 20.4001L18.2795 26.4827C18.0571 26.6995 17.701 26.6953 17.4839 26.4728C17.3773 26.3634 17.3241 26.2217 17.3241 26.0799Z"
                  fill="white"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#AFAFAF"
                />
              </svg>
            </Box>
          ) : (
            <div />
          )}
          {typeof desktop !== "boolean" ? desktop : undefined}
        </Box>
      ) : undefined}
    </>
  );
};
